import React, { Fragment } from "react";
import { Row, Col, Card, Divider, Button, Input, Modal, Tabs, Select, Drawer } from "antd";
import { SearchOutlined, ArrowLeftOutlined, CaretDownOutlined, CloseOutlined, SyncOutlined } from "@ant-design/icons";
import { htmlPrint } from "../../../lib/printer/htmlPrinter";
import { xmlPrint } from "../../../lib/printer/xmlPrinter";
import { Scrollbars } from "react-custom-scrollbars";
import DropdownCircle from "../../../assets/images/DropdownCircle.png";
import Backspace from "../../../assets/images/backspac.png";
import Tick from "../../../assets/images/tick-small.svg";
import AspectRatio from "react-aspect-ratio";
import "react-aspect-ratio/aspect-ratio.css";
import "../../style.css";
import "../../globalStyles.css";
import data from "../../../constants/retailPos.json";

// OMS Orders Modal Imports //
import POSLogo from "../../../assets/images/cwPosLogo.png";
import MobileIcon from "../../../assets/images/mobileIcon.svg";
import _ from "lodash";

// GLOBAL CONSTANTS START
const TabPane = Tabs.TabPane;
const { Option } = Select;
// GLOBAL CONSTANTS END

const PointOfSaleRetailModals = (props) => {
  const {
    displayOrderHistory,
    setDisplayOrderHistory,
    searchOrderHistory,
    showOrderHistory,
    setOrderHistoryInput,
    orderHistoryDetails,
    showOrderHistoryLine,
    selectedOrderHistoryLine,
    isPrintModeXML,
    syncOrders,
    displayParkedBillModal,
    closeParkedBillModal,
    handleParkedBillSearchInput,
    searchParkedBill,
    parkedBillSearchInput,
    filterdParkedList,
    selectParkedBill,
    displaySetOrderType,
    setDisplayOrderType,
    posSaleTypes,
    changeOrderType,
    displayOfferProductSelectiton,
    setDisplayOfferProductSelection,
    offerProductsList,
    selectOfferProduct,
    displayBatchSelection,
    setDisplayBatchSelection,
    batchSetAvailable,
    selectProductToCart,
    displayManualQtyWeightInput,
    setDisplayManualQtyWeightInput,
    setDefaultImage,
    currentWeightSelectedProduct,
    productWeightModalInput,
    onProductModalChangeWeight,
    addManualWeightToProduct,
    handleWeightManual,
    couponModalVisible,
    closeCouponModal,
    checkCoupon,
    setCouponInput,
    loyalityOtpModalVisible,
    processOtpInput,
    setLoyaltyPaymentOtp,
    // OMS Order MOdal and Side menu drawer//,
    displayOMSOrderItemsModal,
    omsOrderStatus,
    selectedOMSOrderStatus,
    setSelectedOrder,
    handleOMSOrderStatusSelection,
    order,
    handleOrderSearchInput,
    searchOrders,
    selectedOrder,
    handleOrderCard,
    tillData,
    handleOmsOrders,
    setDisplayOMSOrderItemsModal,
    handleOMSStatusButton,
    omsOrderTotalPrice,
    onClose,
    sideMenuDrawervisible,
  } = props;

  return (
    <Fragment>
      {/* ORDER HISTORY MODAL BEGIN*/}
      <Modal
        visible={displayOrderHistory}
        closable={false}
        footer={null}
        // width="52%"
        bodyStyle={{ padding: "20px 15px 15px" }}
      >
        <Row gutter={[0, 8]}>
          <Col span={8}>
            <ArrowLeftOutlined style={data.posScreenStyles.orderHistoryModal.backArrowIcon} onClick={() => setDisplayOrderHistory(false)} />
          </Col>
          <Col span={16}>
            <p style={data.posScreenStyles.orderHistoryModal.orderHistoryModalTitle}>Order History</p>
          </Col>
        </Row>
        <Row gutter={[8, 0]}>
          <Col span={10}>
            <Select
              defaultValue="orderDocumentNo"
              suffixIcon={<CaretDownOutlined />}
              placeholder="Document"
              style={data.posScreenStyles.orderHistoryModal.orderHistoryAttribute}
              size="large"
            >
              <Option value="orderDocumentNo">Document</Option>
              <Option value="orderCustomerSearchKey">Customer SearchKey</Option>
            </Select>
          </Col>
          <Col span={14}>
            <Input
              placeholder="Search"
              size="large"
              allowClear={false}
              style={data.posScreenStyles.orderHistoryModal.orderHistorySearchInput}
              onPressEnter={searchOrderHistory}
              onChange={(e) => {
                e.target.value === "" ? showOrderHistory() : setOrderHistoryInput(e.target.value);
              }}
              suffix={<SearchOutlined onClick={searchOrderHistory} style={data.posScreenStyles.customerModal.searchIcon} />}
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Tabs defaultActiveKey="1" centered style={data.posScreenStyles.orderHistoryModal.orderHistoryTabs}>
            <TabPane tab="All" key="1">
              <Row gutter={[8, 8]} style={{ margin: "0px" }}>
                <Card className="orderHistoryCard" style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 10 }}>
                  <Scrollbars autoHide className="orderHistoryScroll">
                    {orderHistoryDetails.map((item) => (
                      <Card key={item.documentno} style={data.posScreenStyles.orderHistoryModal.oderHistoryDetailsCard} bodyStyle={{ padding: 10 }}>
                        <Row>
                          <Col span={11}>
                            <p style={data.posScreenStyles.orderHistoryModal.orderDate}>{item.orderTime}</p>
                          </Col>
                          <Col span={11}>
                            <p style={data.posScreenStyles.orderHistoryModal.orderCustomerName}>{item.customer.name}</p>
                          </Col>
                          <Col span={2}>
                            <img
                              style={data.posScreenStyles.orderHistoryModal.dropdownCircleIcon}
                              src={DropdownCircle}
                              alt=""
                              width={15}
                              height={15}
                              onClick={() => showOrderHistoryLine(item.sOrderID)}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12}>
                            <p style={data.posScreenStyles.orderHistoryModal.orderNum}>{item.documentno}</p>
                          </Col>
                          <Col span={10}>
                            <p style={data.posScreenStyles.orderHistoryModal.orderStatus}>{item.isSynced ? "Success" : "Pending"}</p>
                          </Col>
                          <Col span={2}></Col>
                        </Row>
                        {item.sOrderID === selectedOrderHistoryLine ? (
                          <Row>
                            <table style={data.posScreenStyles.orderHistoryModal.orderDetailsTable}>
                              <tr style={data.posScreenStyles.orderHistoryModal.orderDetailsTableHeader}>
                                <th>Description</th>
                                <th style={data.posScreenStyles.orderHistoryModal.orderDetailsTableTh}>Qty</th>
                                <th style={data.posScreenStyles.orderHistoryModal.orderDetailsTableTh}>Unit Price</th>
                                <th style={data.posScreenStyles.orderHistoryModal.orderDetailsTableTh}>Net Total</th>
                              </tr>
                              {item.items.map((subItem, index) => (
                                <tr key={`${subItem.name}-${index}`} style={data.posScreenStyles.orderHistoryModal.orderDetailsData}>
                                  <td style={data.posScreenStyles.orderHistoryModal.orderDetailsDesc}>{subItem.name}</td>
                                  <td>{subItem.weight}</td>
                                  <td>{parseFloat(subItem.salePrice).toFixed(2)}</td>
                                  <td>{parseFloat(subItem.nettotal).toFixed(2)}</td>
                                </tr>
                              ))}
                            </table>
                            <Divider />
                            <Row style={data.posScreenStyles.orderHistoryModal.orderDetailsTotalTaxes}>
                              <Col span={12}>
                                <p>Taxes</p>
                              </Col>
                              <Col span={12}>
                                <p style={data.posScreenStyles.orderHistoryModal.dropdownCircleIcon}>{parseFloat(item.tax).toFixed(2)}</p>
                              </Col>
                            </Row>
                            <Row style={data.posScreenStyles.orderHistoryModal.orderDetailsTotalTaxes}>
                              <Col span={12}>
                                <p>Total</p>
                              </Col>
                              <Col span={12}>
                                <p style={data.posScreenStyles.orderHistoryModal.dropdownCircleIcon}>{parseFloat(item.total).toFixed(2)}</p>
                              </Col>
                            </Row>
                            <Row style={data.posScreenStyles.orderHistoryModal.orderDetailsButtons}>
                              <Col span={12}>
                                <Button
                                  size="large"
                                  style={data.posScreenStyles.orderHistoryModal.rePrintBtn}
                                  onClick={() => (isPrintModeXML ? xmlPrint(item, "Duplicate Copy") : htmlPrint(item, "Duplicate Copy"))}
                                >
                                  Re-Print
                                </Button>
                              </Col>
                              <Col span={12}>
                                <Button size="large" style={data.posScreenStyles.orderHistoryModal.returnBtn}>
                                  Return
                                </Button>
                              </Col>
                            </Row>
                          </Row>
                        ) : (
                          ""
                        )}
                      </Card>
                    ))}
                  </Scrollbars>
                </Card>
              </Row>
            </TabPane>
            <TabPane tab="Sucess" key="2">
              <Row gutter={[8, 8]} style={{ margin: "0px" }}>
                <Card className="orderHistoryCard" style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 10 }}>
                  <Scrollbars autoHide className="orderHistoryScroll">
                    {orderHistoryDetails.map((item) =>
                      item.isSynced ? (
                        <Card key={item.documentno} style={data.posScreenStyles.orderHistoryModal.oderHistoryDetailsCard} bodyStyle={{ padding: 10 }}>
                          <Row>
                            <Col span={11}>
                              <p style={data.posScreenStyles.orderHistoryModal.orderDate}>{item.orderTime}</p>
                            </Col>
                            <Col span={11}>
                              <p style={data.posScreenStyles.orderHistoryModal.orderCustomerName}>{item.customer.name}</p>
                            </Col>
                            <Col span={2}>
                              <img
                                style={data.posScreenStyles.orderHistoryModal.dropdownCircleIcon}
                                src={DropdownCircle}
                                alt=""
                                width={15}
                                height={15}
                                onClick={() => showOrderHistoryLine(item.sOrderID)}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={12}>
                              <p style={data.posScreenStyles.orderHistoryModal.orderNum}>{item.documentno}</p>
                            </Col>
                            <Col span={10}>
                              <p style={data.posScreenStyles.orderHistoryModal.orderStatus}>{item.isSynced ? "Success" : "Pending"}</p>
                            </Col>
                            <Col span={2}></Col>
                          </Row>
                          {item.sOrderID === selectedOrderHistoryLine ? (
                            <Row>
                              <table style={data.posScreenStyles.orderHistoryModal.orderDetailsTable}>
                                <tr style={data.posScreenStyles.orderHistoryModal.orderDetailsTableHeader}>
                                  <th>Description</th>
                                  <th style={data.posScreenStyles.orderHistoryModal.orderDetailsTableTh}>Qty</th>
                                  <th style={data.posScreenStyles.orderHistoryModal.orderDetailsTableTh}>Unit Price</th>
                                  <th style={data.posScreenStyles.orderHistoryModal.orderDetailsTableTh}>Net Total</th>
                                </tr>
                                {item.items.map((subItem, index) => (
                                  <tr key={`${subItem.name}-${index}`} style={data.posScreenStyles.orderHistoryModal.orderDetailsData}>
                                    <td style={data.posScreenStyles.orderHistoryModal.orderDetailsDesc}>{subItem.name}</td>
                                    <td>{subItem.weight}</td>
                                    <td>{subItem.salePrice}</td>
                                    <td>{subItem.nettotal}</td>
                                  </tr>
                                ))}
                              </table>
                              <Row style={data.posScreenStyles.orderHistoryModal.orderDetailsTotalTaxes}>
                                <Col span={12}>
                                  <p>Taxes</p>
                                </Col>
                                <Col span={12}>
                                  <p style={data.posScreenStyles.orderHistoryModal.dropdownCircleIcon}>{parseFloat(item.tax).toFixed(2)}</p>
                                </Col>
                              </Row>
                              <Row style={data.posScreenStyles.orderHistoryModal.orderDetailsTotalTaxes}>
                                <Col span={12}>
                                  <p>Total</p>
                                </Col>
                                <Col span={12}>
                                  <p style={data.posScreenStyles.orderHistoryModal.dropdownCircleIcon}>{parseFloat(item.total).toFixed(2)}</p>
                                </Col>
                              </Row>
                              <Row style={data.posScreenStyles.orderHistoryModal.orderDetailsButtons}>
                                <Col span={12}>
                                  <Button size="large" style={data.posScreenStyles.orderHistoryModal.rePrintBtn}>
                                    Re-print
                                  </Button>
                                </Col>
                                <Col span={12}>
                                  <Button size="large" style={data.posScreenStyles.orderHistoryModal.returnBtn}>
                                    Return
                                  </Button>
                                </Col>
                              </Row>
                            </Row>
                          ) : (
                            ""
                          )}
                        </Card>
                      ) : (
                        ""
                      )
                    )}
                  </Scrollbars>
                </Card>
              </Row>
            </TabPane>
            <TabPane tab="Pending" key="3">
              <Row gutter={[8, 8]} style={{ margin: "0px" }}>
                <Card className="orderHistoryCard" style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 10 }}>
                  <Scrollbars autoHide className="orderHistoryScroll">
                    <Button
                      style={data.posScreenStyles.orderHistoryModal.syncOrderBtn}
                      onClick={() => {
                        syncOrders("orderHistory");
                      }}
                    >
                      Sync Now
                    </Button>
                    {orderHistoryDetails.map((item) =>
                      parseInt(item.isSynced) === 0 || parseInt(item.isSynced) === 2 ? (
                        <Card key={item.documentno} style={data.posScreenStyles.orderHistoryModal.oderHistoryDetailsCard} bodyStyle={{ padding: 10 }}>
                          <Row>
                            <Col span={11}>
                              <p style={data.posScreenStyles.orderHistoryModal.orderDate}>{item.orderTime}</p>
                            </Col>
                            <Col span={11}>
                              <p style={data.posScreenStyles.orderHistoryModal.orderCustomerName}>{item.customer.name}</p>
                            </Col>
                            <Col span={2}>
                              <img
                                style={data.posScreenStyles.orderHistoryModal.dropdownCircleIcon}
                                src={DropdownCircle}
                                alt=""
                                width={15}
                                height={15}
                                onClick={() => showOrderHistoryLine(item.sOrderID)}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={12}>
                              <p style={data.posScreenStyles.orderHistoryModal.orderNum}>{item.documentno}</p>
                            </Col>
                            <Col span={10}>
                              <p style={data.posScreenStyles.orderHistoryModal.orderStatus}>{item.isSynced ? "Success" : "Pending"}</p>
                            </Col>
                            <Col span={2}></Col>
                          </Row>
                          {item.sOrderID === selectedOrderHistoryLine ? (
                            <Row>
                              <table style={data.posScreenStyles.orderHistoryModal.orderDetailsTable}>
                                <tr style={data.posScreenStyles.orderHistoryModal.orderDetailsTableHeader}>
                                  <th>Description</th>
                                  <th style={data.posScreenStyles.orderHistoryModal.orderDetailsTableTh}>Qty</th>
                                  <th style={data.posScreenStyles.orderHistoryModal.orderDetailsTableTh}>Unit Price</th>
                                  <th style={data.posScreenStyles.orderHistoryModal.orderDetailsTableTh}>Net Total</th>
                                </tr>
                                {item.items.map((subItem, index) => (
                                  <tr key={`${subItem.name}-${index}`} style={data.posScreenStyles.orderHistoryModal.orderDetailsData}>
                                    <td style={data.posScreenStyles.orderHistoryModal.orderDetailsDesc}>{subItem.name}</td>
                                    <td>{subItem.weight}</td>
                                    <td>{subItem.salePrice}</td>
                                    <td>{subItem.nettotal}</td>
                                  </tr>
                                ))}
                              </table>
                              <Row style={data.posScreenStyles.orderHistoryModal.orderDetailsTotalTaxes}>
                                <Col span={12}>
                                  <p>Taxes</p>
                                </Col>
                                <Col span={12}>
                                  <p style={data.posScreenStyles.orderHistoryModal.dropdownCircleIcon}>{parseFloat(item.tax).toFixed(2)}</p>
                                </Col>
                              </Row>
                              <Row style={data.posScreenStyles.orderHistoryModal.orderDetailsTotalTaxes}>
                                <Col span={12}>
                                  <p>Total</p>
                                </Col>
                                <Col span={12}>
                                  <p style={data.posScreenStyles.orderHistoryModal.dropdownCircleIcon}>{parseFloat(item.total).toFixed(2)}</p>
                                </Col>
                              </Row>
                              <Row style={data.posScreenStyles.orderHistoryModal.orderDetailsButtons}>
                                <Col span={12}>
                                  <Button size="large" style={data.posScreenStyles.orderHistoryModal.rePrintBtn}>
                                    Re-print
                                  </Button>
                                </Col>
                                <Col span={12}>
                                  <Button size="large" style={data.posScreenStyles.orderHistoryModal.returnBtn}>
                                    Return
                                  </Button>
                                </Col>
                              </Row>
                            </Row>
                          ) : (
                            ""
                          )}
                        </Card>
                      ) : (
                        ""
                      )
                    )}
                  </Scrollbars>
                </Card>
              </Row>
            </TabPane>
          </Tabs>
        </Row>
      </Modal>
      {/* ORDER HISTORY MODAL END*/}

      {/* PARKED BILLS MODAL BEGIN*/}
      <Modal
        visible={displayParkedBillModal}
        closable={false}
        footer={null}
        // width="35%"
        bodyStyle={{ padding: "20px 15px 15px" }}
      >
        <Row>
          <Col span={9}>
            <ArrowLeftOutlined style={data.posScreenStyles.orderHistoryModal.backArrowIcon} onClick={closeParkedBillModal} />
          </Col>
          <Col span={15}>
            <p style={data.posScreenStyles.parkedBillModal.parkedBillsHistoryModalTitle}>Parked Orders</p>
          </Col>
        </Row>
        <Row style={{ paddingBottom: "12px" }}>
          <Col span={24}>
            <Input
              placeholder={`Customer Search Key`}
              size="large"
              allowClear={false}
              style={data.posScreenStyles.orderHistoryModal.orderHistorySearchInput}
              onPressEnter={searchParkedBill}
              onChange={handleParkedBillSearchInput}
              value={parkedBillSearchInput}
              suffix={<SearchOutlined onClick={searchParkedBill} style={data.posScreenStyles.customerModal.searchIcon} />}
            />
            {/* <Input
              onChange={handleParkedBillSearchInput}
              onPressEnter={searchParkedBill}
              value={parkedBillSearchInput}
              placeholder={`Customer Search Key`}
            /> */}
          </Col>
          {/* <Col span={4}>
            <Button onClick={searchParkedBill}>Search</Button>
          </Col> */}
        </Row>
        <Row>
          <Card className="orderHistoryCard" style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 10 }}>
            <Scrollbars autoHide className="orderHistoryScroll">
              {filterdParkedList.map((item, index) => (
                <Card key={`${index + 1}`} style={data.posScreenStyles.orderHistoryModal.oderHistoryDetailsCard} bodyStyle={{ padding: 10 }} onClick={() => selectParkedBill(item)}>
                  <Row>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderDate}>{item.parkedTime}</p>
                    </Col>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderCustomerName}>{index + 1}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderNum}>{item.parkedCart.customer.name}</p>
                    </Col>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderStatus}>Parked</p>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Scrollbars>
          </Card>
        </Row>
      </Modal>
      {/* PARKED BILLS MODAL END*/}

      {/* ORDER TYPE SELECTION MODAL START*/}
      <Modal
        visible={displaySetOrderType}
        closable={false}
        footer={null}
        // width="35%"
        bodyStyle={{ padding: "20px 15px 15px" }}
      >
        <Row>
          <Col span={14}>
            <p style={data.posScreenStyles.orderTypeSelectionModal.modalTitle}>Order Type</p>
          </Col>
          <Col span={10}>
            <p style={data.posScreenStyles.offerSelectionModal.closeIconP}>
              <CloseOutlined onClick={() => setDisplayOrderType(false)} style={data.posScreenStyles.offerSelectionModal.closeIcon} />
            </p>
          </Col>
        </Row>
        <Row>
          <Card className="orderHistoryCard" style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 10 }}>
            <Scrollbars autoHide className="orderHistoryScroll">
              {posSaleTypes.map((item, index) => (
                <Card
                  key={`${item.name}-${index}`}
                  style={data.posScreenStyles.orderHistoryModal.oderHistoryDetailsCard}
                  bodyStyle={{ padding: 20 }}
                  onClick={() => changeOrderType(item)}
                >
                  <Row>
                    <Col span={24}>
                      <p style={data.posScreenStyles.orderTypeSelectionModal.orderTypeName}>{item.cwrSaletype.name}</p>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Scrollbars>
          </Card>
        </Row>
      </Modal>
      {/* ORDER TYPE SELECTION MODAL END*/}

      {/* OFFER SELECTION MODAL BEGIN*/}
      <Modal
        visible={displayOfferProductSelectiton}
        footer={null}
        closable={false}
        bodyStyle={{ padding: 0 }}
        // width="30%"
      >
        <Row style={data.posScreenStyles.offerSelectionModal.mainRow}>
          <Col span={12}>
            <h3 style={data.posScreenStyles.offerSelectionModal.title}>Select an Offer Product</h3>
          </Col>
          <Col span={12}>
            <p style={data.posScreenStyles.offerSelectionModal.closeIconP}>
              <CloseOutlined onClick={() => setDisplayOfferProductSelection(false)} style={data.posScreenStyles.offerSelectionModal.closeIcon} />
            </p>
          </Col>
        </Row>
        {offerProductsList
          ? offerProductsList?.map((offerData, index) => (
              <Row key={`${offerData.name}-${index}`} style={data.posScreenStyles.offerSelectionModal.offerBtnsRow}>
                <Col span={24}>
                  <Button size="large" onClick={() => selectOfferProduct(offerData)} style={data.posScreenStyles.offerSelectionModal.offerBtn}>
                    {offerData.name}
                  </Button>
                </Col>
              </Row>
            ))
          : ""}
      </Modal>
      {/* OFFER SELECTION MODAL END*/}

      {/* BATCH SELECTION MODAL START*/}
      <Modal
        visible={displayBatchSelection}
        closable={true}
        footer={null}
        onCancel={() => setDisplayBatchSelection(false)}
        // width="35%"
        bodyStyle={{ padding: "20px 15px 15px" }}
      >
        <Row>
          <Col span={12}>
            <p style={data.posScreenStyles.offerSelectionModal.title}>Product Batch</p>
          </Col>
          <Col span={12}>
            <p style={data.posScreenStyles.offerSelectionModal.closeIconP}>
              <CloseOutlined onClick={() => setDisplayBatchSelection(false)} style={data.posScreenStyles.offerSelectionModal.closeIcon} />
            </p>
          </Col>
        </Row>
        <Row>
          <Card className="orderHistoryCard" style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 10 }}>
            <Scrollbars autoHide className="orderHistoryScroll">
              {batchSetAvailable.map((item, index) => (
                <Card
                  key={`${item.batchno}-${index}`}
                  style={data.posScreenStyles.orderHistoryModal.oderHistoryDetailsCard}
                  bodyStyle={{ padding: 10 }}
                  onClick={() => selectProductToCart(item)}
                >
                  <Row>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderDate}>Batch</p>
                    </Col>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderCustomerName}>Price</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderNum}>{item.batchno}</p>
                    </Col>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderStatus}>&#8377; {parseFloat(item.salePrice).toFixed(2)}</p>
                    </Col>
                  </Row>
                </Card>
              ))}
            </Scrollbars>
          </Card>
        </Row>
      </Modal>
      {/* BATCH SELECTION MODAL END*/}

      {/* PRODUCT WEIGHT MODAL START */}
      <Modal visible={displayManualQtyWeightInput} closable={false} onCancel={() => setDisplayManualQtyWeightInput(false)} footer={null} width="45%" bodyStyle={{ padding: 10 }}>
        <Row>
          <Col span={12}>
            <p style={data.posScreenStyles.productWeightModal.modalTitle}>Enter the product quantity</p>
          </Col>
          <Col span={12}>
            <p style={data.posScreenStyles.productWeightModal.closeIconP}>
              <CloseOutlined onClick={() => setDisplayManualQtyWeightInput(false)} style={data.posScreenStyles.productWeightModal.closeIcon} />
            </p>
          </Col>
        </Row>
        <Row gutter={[8, 0]}>
          <Col span={9}>
            <Card bodyStyle={{ padding: 10 }}>
              <img onError={setDefaultImage} style={data.posScreenStyles.productWeightModal.imgStyles} src={currentWeightSelectedProduct.imageurl} alt="" />
            </Card>
            <Row style={{ paddingTop: 10 }}>
              <h3 style={data.posScreenStyles.productWeightModal.prodHead}>{currentWeightSelectedProduct.name || ""}</h3>
            </Row>
            <Row>
              <Col span={9}>
                <h3 style={data.posScreenStyles.productWeightModal.prodHead}>MRP</h3>
                <h3 style={data.posScreenStyles.productWeightModal.prodHead}>Quantity</h3>
              </Col>
              <Col span={15}>
                <p style={data.posScreenStyles.productWeightModal.prodPriceValue}>Rs. {(currentWeightSelectedProduct.salePrice || 0).toFixed(2)}</p>
                <p style={data.posScreenStyles.productWeightModal.prodPriceValue}>{productWeightModalInput || 0}</p>
              </Col>
            </Row>
          </Col>
          <Col span={15}>
            <Card bodyStyle={{ padding: 15 }}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Input value={productWeightModalInput} onChange={onProductModalChangeWeight} style={data.posScreenStyles.paymentModal.topayInput} size="large" />
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("1")}>
                      1
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("2")}>
                      2
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("3")}>
                      3
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("x")}>
                      <img src={Backspace} alt="back space" width={20} height={15} />
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("4")}>
                      4
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("5")}>
                      5
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("6")}>
                      6
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual(".")}>
                      .
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("7")}>
                      7
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("8")}>
                      8
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("9")}>
                      9
                    </button>
                  </AspectRatio>
                </Col>
                <Col span={6}>
                  <AspectRatio ratio="1">
                    <button className="amt-dial-btn" onClick={() => handleWeightManual("0")}>
                      0
                    </button>
                  </AspectRatio>
                </Col>
                <Button size="large" style={data.posScreenStyles.productWeightModal.confirmQtyBtn} onClick={addManualWeightToProduct}>
                  <img src={Tick} alt="" />
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>
      </Modal>
      {/* PRODUCT WEIGHT MODAL END */}

      {/* COUPON MODAL START */}
      <Modal visible={couponModalVisible} closable={true} onCancel={closeCouponModal} footer={null} onclosefooter={null} width="35%" bodyStyle={{ padding: "20px 15px 15px" }}>
        <Row style={{ textAlign: "center" }}>Enter Coupon Code</Row>
        <Row style={{ padding: "30px" }}>
          <Col span={20}>
            <Input onPressEnter={checkCoupon} onChange={(e) => setCouponInput(e.target.value)} />
          </Col>
          <Col span={4}>
            <Button onClick={checkCoupon}>Apply</Button>
          </Col>
        </Row>
      </Modal>
      {/* COUPON MODAL END */}

      {/* LOYALITY OTP MODAL START */}
      <Modal visible={loyalityOtpModalVisible} closable={true} onCancel={null} footer={null} onclosefooter={null} width="35%" bodyStyle={{ padding: "20px 15px 15px" }}>
        <Row style={{ textAlign: "center" }}>Enter OTP</Row>
        <Row style={{ padding: "30px" }}>
          <Col span={20}>
            <Input onPressEnter={processOtpInput} onChange={(e) => setLoyaltyPaymentOtp(e.target.value)} />
          </Col>
          <Col span={4}>
            <Button onClick={processOtpInput}>Verify</Button>
          </Col>
        </Row>
      </Modal>
      {/* LOYALITY OTP MODAL END */}

      {/* OMS Orders Modal Starts */}
      <Modal
        closable={false}
        footer={null}
        bodyStyle={{ padding: 0, height: "100%" }}
        visible={displayOMSOrderItemsModal}
        // onCancel={handleAddOnModalClose}
        style={{ top: 50 }}
        width="90%"
      >
        <Row>
          <Col span={2}>
            <Row>
              {omsOrderStatus.map((statusItem, index) => (
                <Col
                  span={24}
                  style={
                    statusItem?.title === selectedOMSOrderStatus.title
                      ? data.posScreenStyles.omsOrdersModal.selectedOMSOrderStatusOptionsCol
                      : data.posScreenStyles.omsOrdersModal.omsOrderStatusOptionsCol
                  }
                  onClick={() => {
                    setSelectedOrder({});
                    handleOMSOrderStatusSelection(statusItem);
                  }}
                >
                  <img
                    src={statusItem?.title === selectedOMSOrderStatus.title ? statusItem?.imgSrc : statusItem?.selectedImgSrc}
                    alt={statusItem?.imgSrc}
                    style={{ height: "46px" }}
                  />
                  <h6
                    style={
                      statusItem?.title === selectedOMSOrderStatus.title
                        ? data.posScreenStyles.omsOrdersModal.selectedOMSStatusOptionName
                        : data.posScreenStyles.omsOrdersModal.omsStatusOptionName
                    }
                  >
                    {statusItem?.title}
                  </h6>
                </Col>
              ))}
            </Row>
          </Col>
          <Col span={6}>
            <Row>
              <Col span={24}>
                <p style={data.posScreenStyles.omsOrdersModal.manageOrders}>Manage Orders</p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Input
                  size="large"
                  placeholder="Search Order No"
                  value={order}
                  onChange={(e) => {
                    handleOrderSearchInput(e.target.value);
                  }}
                  allowClear
                  suffix={<SearchOutlined style={{ fontSize: "14px" }} />}
                  className="orderIdInput"
                  style={data.posScreenStyles.omsOrdersModal.orderIdInput}
                />
                <div style={{ backgroundColor: "#F5F5F7", marginTop: "3px" }}>
                  <Scrollbars autoHide className="omsOrderCardsScroll">
                    {searchOrders && searchOrders.length > 0
                      ? _.filter(searchOrders, (searchItem) => searchItem.status === selectedOMSOrderStatus.statusValue)?.map((item, index) => (
                          <div>
                            <Card
                              bodyStyle={{ padding: 10 }}
                              style={
                                item.orderNo === selectedOrder.orderNo
                                  ? data.posScreenStyles.omsOrdersModal.selectedOrderDetailsCard
                                  : data.posScreenStyles.omsOrdersModal.orderDetailsCard
                              }
                              onClick={() => handleOrderCard(item)}
                            >
                              <p style={data.posScreenStyles.omsOrdersModal.orderIdValue}>OrderID: #{item.orderNo}</p>
                              <p style={data.posScreenStyles.omsOrdersModal.itemsNum}>
                                {item.noOfItems} items for &#8377;
                                {_.sumBy(item.lineItems, "price").toFixed(2)}
                              </p>
                              <p style={data.posScreenStyles.omsOrdersModal.dateCreated}>{item.dateCreated}</p>
                            </Card>
                            {/* <hr style={{ margin: 0, backgroundColor: "#FFFFFF11" }} /> */}
                          </div>
                        ))
                      : ""}
                  </Scrollbars>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={16} style={{ padding: "3px 10px" }}>
            <Row>
              <Col span={12}>
                <p style={data.posScreenStyles.omsOrdersModal.omsCustTitle}>{tillData.tillAccess.csBunit.name}</p>
              </Col>
              <Col span={11} style={{ paddingTop: 5 }}>
                <p style={data.posScreenStyles.omsOrdersModal.closeIconP}>
                  <SyncOutlined onClick={() => handleOmsOrders()} style={data.posScreenStyles.omsOrdersModal.closeIcon} />
                </p>
              </Col>
              <Col span={1} style={{ paddingTop: 5 }}>
                <p style={data.posScreenStyles.omsOrdersModal.closeIconP}>
                  <CloseOutlined
                    onClick={() => {
                      setDisplayOMSOrderItemsModal(false);
                      // localStorage.setItem('orderDetails', "[]");
                    }}
                    style={data.posScreenStyles.omsOrdersModal.closeIcon}
                  />
                </p>
              </Col>
            </Row>
            {Object.keys(selectedOrder).length > 0 ? (
              <>
                <Row style={{ marginTop: "2rem" }}>
                  <Col span={12}>
                    <p style={data.posScreenStyles.omsOrdersModal.storeItems}>Store Items</p>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      display:
                        selectedOMSOrderStatus.title === "Today's Orders" || selectedOMSOrderStatus.statusValue !== selectedOrder.status || selectedOrder.posOrders === "Y"
                          ? "none"
                          : "block",
                    }}
                  >
                    <Button size="medium" style={data.posScreenStyles.omsOrdersModal.statusBtn} className="addButton" onClick={() => handleOMSStatusButton(selectedOrder)}>
                      <span style={data.posScreenStyles.omsOrdersModal.statusBtn.label}>
                        {selectedOrder.status === "NE" ? "Accept" : selectedOrder.status === "UP" ? "Prepared" : selectedOrder.status === "PK" ? "Completed" : "Accept"}
                      </span>
                    </Button>
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col span={24}>
                    <Card bodyStyle={{ padding: 0 }} style={data.posScreenStyles.omsOrdersModal.omsOrderDetails}>
                      <Row
                        style={{
                          padding: 10,
                          paddingBottom: 5,
                          borderBottom: "1px solid #DCDCDC",
                        }}
                      >
                        <Col span={12}>
                          <p style={data.posScreenStyles.omsOrdersModal.omsOrderCustDetails}>OrderID. #{selectedOrder.orderNo}</p>
                          <p style={data.posScreenStyles.omsOrdersModal.omsOrderCustDetails}>{selectedOrder.customerName || "Anonymous"}</p>
                        </Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                          <p style={data.posScreenStyles.omsOrdersModal.omsOrderCustDetails}>{selectedOrder.dateCreated}</p>
                          <p style={data.posScreenStyles.omsOrdersModal.omsOrderCustDetails}>{selectedOrder.noOfItems} Items</p>
                        </Col>
                      </Row>
                      <div style={{ borderBottom: "1px solid #DCDCDC" }}>
                        <Row
                          style={{
                            padding: 10,
                            paddingBottom: 0,
                          }}
                        >
                          <Col span={8}>
                            <p style={data.posScreenStyles.omsOrdersModal.tableHeadingName}>Item</p>
                          </Col>
                          <Col span={4}>
                            <p style={data.posScreenStyles.omsOrdersModal.tableHeadingQty}>Qty</p>
                          </Col>
                          <Col span={6}>
                            <p style={data.posScreenStyles.omsOrdersModal.tableHeadingPrice}>Price</p>
                          </Col>
                          <Col span={6}>
                            <p style={data.posScreenStyles.omsOrdersModal.tableHeadingPrice}>Total Price</p>
                          </Col>
                        </Row>
                        <Scrollbars autoHide className="orderDetailsTableScroll">
                          {selectedOrder?.lineItems?.map((productItem, index) => (
                            <Row
                              style={{
                                padding: 10,
                                paddingBottom: 0,
                              }}
                            >
                              <Col span={8}>
                                <p style={data.posScreenStyles.omsOrdersModal.tableDataName}>
                                  <img onError={setDefaultImage} src={productItem.img} alt="" style={data.posScreenStyles.omsOrdersModal.productImg} />
                                  <div
                                    style={{
                                      paddingLeft: 10,
                                      alignSelf: "center",
                                    }}
                                  >
                                    {productItem.name}
                                    <br />
                                    <span style={data.posScreenStyles.omsOrdersModal.tableDataProdCode}>{productItem.productCode}</span>
                                  </div>
                                </p>
                              </Col>
                              <Col span={4} style={{ paddingTop: 7 }}>
                                <p style={data.posScreenStyles.omsOrdersModal.tableDataQty}>x &nbsp;&nbsp;&nbsp; {productItem.quantity}</p>
                              </Col>
                              <Col span={6} style={{ paddingTop: 7 }}>
                                <p style={data.posScreenStyles.omsOrdersModal.tableDataPrice}>Rs.{productItem.price.toFixed(2)}</p>
                              </Col>
                              <Col span={6} style={{ paddingTop: 7 }}>
                                <p style={data.posScreenStyles.omsOrdersModal.tableDataPrice}>Rs.{productItem.price.toFixed(2)}</p>
                              </Col>
                            </Row>
                          ))}
                        </Scrollbars>
                      </div>
                      <Row
                        style={{
                          padding: 10,
                        }}
                      >
                        <Col span={12} />
                        <Col span={6}>
                          <p style={data.posScreenStyles.omsOrdersModal.subTotalTaxes}>Sub Total</p>
                          <p style={data.posScreenStyles.omsOrdersModal.subTotalTaxes}>Taxes</p>
                          <p style={data.posScreenStyles.omsOrdersModal.total}>Total</p>
                        </Col>
                        <Col span={6}>
                          <p style={data.posScreenStyles.omsOrdersModal.subTotalTaxes}>Rs.{(omsOrderTotalPrice || 0).toFixed(2)}</p>
                          <p style={data.posScreenStyles.omsOrdersModal.subTotalTaxes}>Rs.0</p>
                          <p style={data.posScreenStyles.omsOrdersModal.total}>Rs.{(omsOrderTotalPrice || 0).toFixed(2)}</p>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Modal>
      {/* OMS Orders Modal Ends */}

      {/* Side Menu Drawer Starts */}
      <Drawer title={<img src={POSLogo} style={data.posScreenStyles.header.posLogoIcon} alt="" />} placement="left" onClose={onClose} visible={sideMenuDrawervisible}>
        <Row>
          <Col span={3}>
            <img src={MobileIcon} alt="Mobile Icon" style={{ width: "10px" }} />
          </Col>
          <Col span={21}>
            <p
              style={{
                marginBottom: 0,
                fontSize: "14px",
                fontWeight: "normal",
                fontFamily: "Poppins",
                letterSpacing: "0px",
                color: "#000000",
                opacity: 1,
                cursor: "pointer",
              }}
              onClick={handleOmsOrders}
            >
              OMS Orders
            </p>
          </Col>
        </Row>
      </Drawer>
      {/* Side Menu Drawer Ends */}
    </Fragment>
  );
};

export default PointOfSaleRetailModals;
