import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, Radio, DatePicker, Upload, Select, Card } from "antd";
import { useHistory } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { clock } from "../../utility/clock";
import MenuIcon from "../../assets/images/menu.png";
// import logoIcon from "../../assets/images/cwpos-blue.png";
import POSLogo from "../../assets/images/cwPosLogo.png";
import "antd/dist/antd.css";
import "./styles.css";
import db from "../../database";

const { Option } = Select;

const FarmerInvoice = () => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const history = useHistory();
  const regNum = localStorage.getItem("lastUpdatedRegNum");

  // CLOCK BLOCK START
  const [displayClock, setDisplayClock] = useState(clock());
  useEffect(() => {
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  // CLOCK BLOCK END

  const handlePosLogo = () => {
    history.push("/dashboard");
  };

  const [form] = Form.useForm();

  useEffect(() => {
    db.formRegistrations
      .where("registrationNum")
      .equals(Number(regNum))
      .limit(1)
      .toArray()
      .then((data) => {
        data.map((item, index) => {
          form.setFieldsValue({
            bankName: item.farmerBankName,
            aadharNumber: item.aadharNum,
            bankBranch: item.farmerBankBranch,
            ifscCode: item.farmerBankIfsc,
            accountNo: item.farmerBankAccNum,
            farmerRegNum: item.registrationNum,
            farmerName: item.farmerName,
            fatherName: item.fatherName,
            village: item.village,
            mandal: item.mandal,
            district: item.district,
            state: item.stateName,
            branch: item.branchName,

          });
        });
      });
  }, []);

  const onRegister = (values) => {
    console.log('values', values);
  };

  return (
    <div>
      <Row className="headerRow">
        <Col span={2}>
          <img src={MenuIcon} className="menuIcon" alt="" />
        </Col>
        <Col span={10}>
          <label className="posHeadLabel">{tillData.tillAccess.csBunit.name}</label>
        </Col>
        <Col span={4}>
          <label className="tillName">{tillData.tillAccess.cwrTill.till}</label>
        </Col>
        <Col span={4} className="right">
          <label className="time">{displayClock}</label>
        </Col>
        <Col span={4} className="right">
          <img src={POSLogo} className="posLogoIcon" alt="" onClick={handlePosLogo} />
        </Col>
      </Row>
      <div className="registrationFormDiv">
        <Form onFinish={onRegister} form={form} name="farmerInvoice">
          <Row gutter={[8, 0]}>
            <Col span={16}>
              <Row gutter={[8, 0]}>
                <Col span={12}>
                  <Row>
                    <Col span={24}>
                      <Card className="registrationCard" bodyStyle={{ padding: 10 }} style={{ marginBottom: 8 }}>
                        <Row gutter="5">
                          <Col span={8}>
                            <label className="registrationLabel">Takpatti/Invoice No</label>
                          </Col>
                          <Col span={16}>
                            <Form.Item name="invoiceNo" className="registrationFormItem">
                              <Input className="registrationInput" placeholder="Takpatti/Invoice No" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col span={24}>
                      <Card className="registrationCard" bodyStyle={{ padding: 10 }}>
                        <Row gutter="5">
                          <Col span={8}>
                            <label className="registrationLabel">Bank Name</label>
                          </Col>
                          <Col span={16}>
                            <Form.Item name="bankName" className="registrationFormItem">
                              <Input className="registrationInput" placeholder="Bank Name" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter="5">
                          <Col span={8}>
                            <label className="registrationLabel">Bank Branch</label>
                          </Col>
                          <Col span={16}>
                            <Form.Item name="bankBranch" className="registrationFormItem">
                              <Input className="registrationInput" placeholder="Bank Branch" />
                            </Form.Item>
                          </Col>
                        </Row>
                          <Row gutter="5">
                            <Col span={8}>
                              <label className="registrationLabel">IFSC Code</label>
                            </Col>
                            <Col span={16}>
                            <Form.Item name="ifscCode" className="registrationFormItem">
                              <Input className="registrationInput" placeholder="IFSC Code" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter="5">
                            <Col span={8}>
                              <label className="registrationLabel">Account No</label>
                            </Col>
                            <Col span={16}>
                            <Form.Item name="accountNo" className="registrationFormItem">
                              <Select
                                placeholder="Select Account No"
                                // onChange={(e) => onSelect(e)}
                                className="accountNumSelect"
                              >
                                <Option>Select</Option>
                              </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Card className="registrationCard" bodyStyle={{ padding: 10 }} style={{ marginBottom: 8 }}>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Purchase Center*</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="purchaseCenter" rules={[{ required: true, message: "Please Enter Purchase Center" }]} className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Purchase Center" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Bidding Dt*</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="biddingDt" rules={[{ required: true, message: "Please Select Bidding Date" }]} className="registrationFormItem">
                          <DatePicker />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Weighment Dt*</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="weightmentDt" rules={[{ required: true, message: "Please Select Weighment Date" }]} className="registrationFormItem">
                          <DatePicker />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Purchase Dt*</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="purchaseDt" rules={[{ required: true, message: "Please Select Purchase Date" }]} className="registrationFormItem">
                          <DatePicker />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Takpatti Dt*</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="takpattiDt" rules={[{ required: true, message: "Please Select Takpatti Date" }]} className="registrationFormItem">
                          <DatePicker />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Variety*</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="variety" rules={[{ required: true, message: "Please Enter Variety" }]} className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Variety" />
                          </Form.Item>
                        </Col>
                      </Row>
                  </Card>
                </Col>
              </Row>
              <Row gutter={[8, 0]}>
                <Col span={12}>
                  <Card className="registrationCard" bodyStyle={{ padding: 10 }}>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Aadhar Number*</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="aadharNumber" rules={[{ required: true, message: "Please Enter Aadhar Num" }]} className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Aadhar Number" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Farmer Reg Num</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="farmerRegNum" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Farmer Reg Num" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Farmer Name</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="farmerName" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Farmer Name" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Father Name</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="fatherName" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Father Name" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Village</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="village" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Village" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Mandal</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="mandal" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Mandal" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">District</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="district" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="District" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">State</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="state" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="State" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Branch</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="branch" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Branch" />
                          </Form.Item>
                        </Col>
                      </Row>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card className="registrationCard" bodyStyle={{ padding: 10 }}>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Vehicle No.</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="vehicleNo" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Vehicle No" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Vehicle Type</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="vehicleType" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Vehicle Type" />
                          </Form.Item>                        
                          </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Operation</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="operation" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Operation" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">MSP / CP</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="msp" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="MSP / CP" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Gross Wt*</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="grossWt" className="registrationFormItem" rules={[{ required: true, message: "Please Enter Gross Wt" }]}>
                          <Input className="registrationInput" placeholder="Gross Wt" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Tare Wt*</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="tareWt" className="registrationFormItem" rules={[{ required: true, message: "Please Enter Tare Wt" }]}>
                          <Input className="registrationInput" placeholder="Tare Wt" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Net Wt</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="netWt" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Net Wt" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Amount (Rs)</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="amount" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Amount (Rs)" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Net Amount (Rs)</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="netAmount" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Net Amount (Rs)" />
                          </Form.Item>
                        </Col>
                      </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row gutter={[0, 8]}>
                <Col span={24}>
                  <Card className="registrationCard" bodyStyle={{ padding: 10 }}>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Base Rate</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="baseRate" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Base Rate" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Mic.</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="mic" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Mic" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Mic Deduction Amt</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="micDeductionAmt" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Mic Deduction Amt" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Mic Increment Amt</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="micIncentiveAmt" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Mic Incentive Amt" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Moisture*</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="moisture" rules={[{ required: true, message: "Please Enter Moisture" }]} className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Moisture" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Moisture Deduction Amt</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="moistureDeductionAmt" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Moisture Deduction Amt" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Moisture Incentive Amt</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="moistureIncentiveAmt" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Moisture Incentive Amt" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Net Rate (Rs./Qtl)</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="netRate" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Net Rate" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Bags/Boras/Loose</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="bagsBorasLoose" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Bags/Boras/Loose" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Loose</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="loose" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Loose" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">No. of Bags</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="bagsCount" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="No. of Bags" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Other Deduction</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="otherDeduction" rules={[{ required: true, message: "Please Enter Other Deduction" }]} className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Other Deduction" />
                          </Form.Item>
                        </Col>
                      </Row>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card className="registrationCard" bodyStyle={{ padding: 10 }}>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Blood Representative*</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="bloodRepresentative" rules={[{ required: true, message: "Please Select Option" }]} className="registrationFormItem">
                          <Radio.Group>
                            <Radio value="yes" className="category">
                              Yes
                            </Radio>
                            <Radio value="no" className="category">
                              No
                            </Radio>
                          </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Total Purchase Till Dt(Qtl)</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="totalPurchase" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Total Purchase" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Total Amt Till Date</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="totalAmt" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Total Amount" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Buyer Name</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="buyerName" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Buyer Name" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Photo Upload*</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="photoUpload" rules={[{ required: true, message: "Please Upload Photo" }]} className="registrationFormItem">
                          <Upload>
                            <Button icon={<UploadOutlined />}>Upload</Button>
                          </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Ginning Mill</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="ginningMill" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Ginning Mill" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Category</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="category" className="registrationFormItem">
                          <Input className="registrationInput" placeholder="Category" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Mobile No*</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="mobileNo" rules={[{ required: true, message: "Please Enter Mobile Number" }]} className="registrationFormItem">
                          <Input type="number" className="registrationInput" placeholder="Mobile No" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter="5">
                        <Col span={8}>
                          <label className="registrationLabel">Thumb Impression</label>
                        </Col>
                        <Col span={16}>
                        <Form.Item name="thumbImpression" className="registrationFormItem">
                        <Upload>
                            <Button icon={<UploadOutlined />}>Upload</Button>
                          </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <div style={{ paddingTop: 8 }}>
              <Row>
                <Col span={24}>
                <Form.Item className="registrationFormItem">
                  <Button size="large" htmlType="submit" className="registerButton">
                    Register
                  </Button>
                  </Form.Item>
                </Col>
              </Row>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default FarmerInvoice;
