import React from "react";
import PointOfSaleCore from "../PointOfSale/Core";
import PointOfSaleRetail from "../PointOfSale/Retail";
import PointOfSaleRestaurant from "../PointOfSale/Restaurant";

const PointOfSale = () => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tillaccess = JSON.parse(tillData?.tillAccess?.cwrTill?.accessController);
  const tillLayout = parseInt(tillaccess?.layout === null || undefined ? 1 : tillaccess?.layout);

  return <PointOfSaleCore component={tillLayout === 1 ? PointOfSaleRetail : PointOfSaleRestaurant} />;
};

export default PointOfSale;
