import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Button, Modal, message, Select, Empty } from "antd";
import { MinusOutlined, PlusOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
import { serverUrl, CWCoreServicesUrl } from "../../constants/config";
import Axios from "axios";
import _ from "lodash";
import { clock } from "../../utility/clock";
// import logoIcon from "../../assets/images/cwpos-blue.png";
import MenuIcon from "../../assets/images/menu.png";
import POSLogo from "../../assets/images/cwPosLogo.png";
import DefaultProductImage from "../../assets/images/default-product.webp";
import "../style.css";
import db from "../../database";
import data from "../../constants/purchaseGrn.json";
import BarCodeComponent from "../../lib/BarCodeComponet";

const { Option } = Select;

const PurchaseGRN = () => {
  const dynamicHeight = 0.5625;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const history = useHistory();

  // CLOCK BLOCK START
  const [displayClock, setDisplayClock] = useState(clock());
  useEffect(() => {
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  // CLOCK BLOCK END

  const handlePosLogo = () => {
    history.push("/dashboard");
  };

  const setDefaultImage = (e) => {
    e.target.src = DefaultProductImage;
  };

  const [supplierList, setSupplierList] = useState([]);
  const [filteredData, setFilteredData] = useState({supplier: "", skuInput: ""})

  useEffect(() => {
    const supplierInput = {
      query: `query {      comboFill(tableName:"p_supplier", pkName:"p_supplier_id", identifier:"value, name, description, referenceno, p_supplier_category_id,(select name from p_supplier_category where p_supplier_category_id = p_supplier.p_supplier_category_id) as suppliercategory, gstno, p_pricelist_id,(select name from p_pricelist where p_pricelist_id = p_supplier.p_pricelist_id) as Pricelistname, (select istaxincluded from p_pricelist where p_pricelist_id = p_supplier.p_pricelist_id) as istaxincluded,islocalpurchase, p_supplierpaymentterms, taxid", whereClause:"p_supplier_id in (select p_supplier_id from p_supplier_bunit where p_bunit_id = '${tillData.tillAccess.csBunit.csBunitId}')")       }`,
    };
    Axios({
      url: CWCoreServicesUrl,
      method: "POST",
      data: supplierInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      const { comboFill } = response.data.data;
      let newComboFill = JSON.parse(comboFill);
      setSupplierList([...newComboFill]);
    });
  }, []);

  const supplierListSelect = supplierList.map((suppList) =>
    suppList.bunit !== null && suppList.islocalpurchase === "Y" ? (
      <Option key={suppList.recordid} value={suppList.recordid}>
        {suppList.name || ""}
      </Option>
    ) : (
      ""
    )
  );

  const [supplierProductList, setSupplierProductList] = useState([]);
  const [supplierAddress, setSupplierAddress] = useState([]);

  // useEffect(() => {
  //   if(window.localStorage.getItem('filterData')) {
  //     let filterobj = JSON.parse(window.localStorage.getItem('filterData'))
  //     setFilteredData(filterobj?.filteredData || {});
  //     setSupplierProductList(filterobj?.filterList || []);
  //   }
  // }, []);

  // useEffect(() => {
  //   window.localStorage.setItem('filterData', JSON.stringify({
  //     filteredData: filteredData,
  //     filterList: supplierProductList
  //   }));
  // }, [filteredData, supplierProductList]);

  const onSelect = (value) => {
    setFilteredData({...filteredData, supplier: value});
    const supplierProductsInput = {
      query: `query {     getSupplierProduct(supplierId : "${value}"     bUnitId : "${tillData.tillAccess.csBunit.csBunitId}"     sku : null     upc : ${null}) {         
        supplierProductId         
        clientId         
        clientName         
        bUnitId         
        bUnitName         
        supplierId         
        supplierName         
        productId         
        productName         
        productCode         
        qtyOnHand         
        uomId         
        uomName         
        productCategoryId         
        productcategoryName         
        taxCategoryId         
        taxCategoryName         
        taxId         
        taxName         
        taxRate         
        priceStd         
        priceList         
        twoWeekSale         
        fourWeekSale         
        upc         
        description         
        margin         
        supp_pricelist_id         
        supp_price_name         
        isdefault         
        istaxincluded   
        batchedProduct
        shelfLife
        salePrice      
        alternateUomList              
        { 
            alternateUomId 
            uomId 
            uomName               
            auomupc 
            conversionrate 
            trxTypeId 
            openOrderQty 
        }         
        sup_minqty         
        sup_uom_id         
        sup_uom_name     
    } 
}`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: supplierProductsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      let newSupplierProduct = [];
      let { getSupplierProduct } = response.data.data;
      let prodCode = _.map([...getSupplierProduct] || [], "productCode");
      db.products
        .where("value")
        .startsWithAnyOfIgnoreCase(`${prodCode}`)
        .toArray()
        .then((productsFetched) => {
          if (productsFetched?.length > 0) {
            newSupplierProduct = [...getSupplierProduct].map((itemLine) => {
              let itemImageIndex = _.findIndex(productsFetched, (item) => item.value === itemLine.productCode);
              return itemImageIndex >= 0
                ? {
                    ...itemLine,
                    img: productsFetched[itemImageIndex]?.imageurl,
                    qty: 0,
                    lineTotal: 0,
                  }
                : { ...itemLine, qty: 0, lineTotal: 0 };
            });
          } else {
            newSupplierProduct = [...getSupplierProduct].map((prodItem) => ({
              ...prodItem,
              qty: 0,
              lineTotal: 0,
            }));
          }

          setSupplierProductList([...newSupplierProduct]);
          const comboFillInput = {
            query: `query {      comboFill(tableName:"p_supplier_address", pkName:"p_supplier_address_id", identifier:"fulladdress", whereClause:"p_supplier_id='${value}'")       }`,
          };
          Axios({
            url: CWCoreServicesUrl,
            method: "POST",
            data: comboFillInput,
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${tokens.token_type} ${tokens.access_token}`,
            },
          }).then((response) => {
            const { comboFill } = response.data.data;
            let newSupplierAddress = JSON.parse(comboFill)
            setSupplierAddress([...newSupplierAddress]);
          });
        });
    });
  };

  const onFieldButton = (fieldIndex, action) => {
    let newSupplierProductList = [...supplierProductList];
    if (action === "plus") {
      newSupplierProductList[fieldIndex].qty += 1;
    } else if (action === "minus" && newSupplierProductList[fieldIndex].qty > 0) {
      newSupplierProductList[fieldIndex].qty -= 1;
    } else if (action !== "plus" && action !== "minus") {
      newSupplierProductList[fieldIndex].qty = Number(action);
    } 
    newSupplierProductList[fieldIndex].lineTotal = newSupplierProductList[fieldIndex].qty * newSupplierProductList[fieldIndex].priceList;
    setSupplierProductList([...newSupplierProductList]);
  };

  const handleSku = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      const skuVal = event.target.value;
      const index = supplierProductList.findIndex((element) => {
        return element.productCode === skuVal || element.productName === skuVal;
      });
      if (index !== -1) {
        supplierProductList[index].qty += 1;
        supplierProductList[index].lineTotal = supplierProductList[index].qty * supplierProductList[index].priceList;
        setSupplierProductList([...supplierProductList]);
      } else {
        message.error("Product not found");
      }
    }
  };

  const handleReceive = () => {
    const formatYmd = (date) => date.toISOString().slice(0, 10);
    const newDate = formatYmd(new Date());
    let supplierId = filteredData.supplier;
    let supplierAddressId = supplierAddress[0].recordid;
    const productArray = [];
    supplierProductList.map((item, index) => {
      item.qty > 0 && productArray.push(
        `{
            productId : "${item.productId}"
            uomId : "${item.uomId}"
            qty : ${item.qty}
            orderLineId : null
            remark : null
            description : null
            stockUomId : "${item.uomId}"
            freeQty : 0
            unitprice : ${item.priceStd}
            listprice : ${item.priceList}
            discountvalue : 0
            discountType: null
            totalDiscount: 0
            batchId:null
            batchNo:null
            expiryDate:null
            storagebinId:null
          }`
      );
    });

    const createGRNInput = {
      query: `mutation {
        createGRN(GRN: {
          bunitId: "${tillData.tillAccess.csBunit.csBunitId}", 
          supplierId: "${supplierId}", 
          supplierAddressId: "${supplierAddressId}", 
          isReturn: "N", 
          orderId: null, 
          remark: "", 
          supplierRef: null, 
          movementDate: "${newDate}", 
          taskId: null, 
          invoiceDate: "${newDate}", 
          receiptLines: [${productArray}]
          landedCost:[]    
      }) {
          type
          code
          message
          documentNo
          extraParam
        }
      }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: createGRNInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${tokens.token_type} ${tokens.access_token}`,
      },
    }).then((response) => {
      if (response.data.data.createGRN.code === '200') {
        message.success(response.data.data.createGRN.message);
        setFilteredData([]);
        setSupplierProductList([]);
      }
    });
  };

  return (
    <div>
      <BarCodeComponent onScan={handleSku} />
      <div style={data.purchaseGrn.header}>
        <Row style={{ height: `${3.7 * dynamicHeight}vw` }}>
          <Col span={2}>
            <img src={MenuIcon} style={data.purchaseGrn.header.menuIcon} alt="" />
          </Col>
          <Col span={10}>
            <label style={data.purchaseGrn.header.posHeadLabel}>{tillData.tillAccess.csBunit.name}</label>
          </Col>
          <Col span={8}>
            <label style={data.purchaseGrn.header.tillName}>{tillData.tillAccess.cwrTill.till}</label>
          </Col>
          <Col span={2} style={data.purchaseGrn.header.right}>
            <label style={data.purchaseGrn.header.time}>{displayClock}</label>
          </Col>
          <Col span={2} style={data.purchaseGrn.header.right}>
            <img onClick={handlePosLogo} src={POSLogo} style={data.purchaseGrn.header.logoIcon} alt="" />
          </Col>
        </Row>
      </div>
      <div style={{ padding: 10 }}>
        <p style={data.purchaseGrn.purchaseText}>Purchase</p>
        <Row style={{ paddingBottom: 10 }}>
          <Col span={12}>
            <Row>
              <Col span={12}>
                <Row>
                  <Col span={5} style={{ alignSelf: "center" }}>
                    <p style={data.purchaseGrn.inputLabel}>Supplier</p>
                  </Col>
                  <Col span={19}>
                    <Select
                      suffixIcon={<CaretDownOutlined />}
                      placeholder="Select"
                      onChange={(e) => onSelect(e)}
                      value={filteredData.supplier}
                      style={data.purchaseGrn.supplierSelect}
                      showSearch
                      // allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      className="supplierSelect"
                    >
                      {supplierListSelect}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={12} style={{ paddingLeft: 10 }}>
                <Row>
                  <Col span={7} style={{ alignSelf: "center" }}>
                    <p style={data.purchaseGrn.inputLabel}>SKU/Name</p>
                  </Col>
                  <Col span={17}>
                    <Input
                      style={data.purchaseGrn.skuInput}
                      className="skuInput"
                      allowClear
                      value={filteredData.skuInput}
                      onChange={(e) => setFilteredData({...filteredData, skuInput: e.target.value})}
                      onKeyPress={handleSku}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={12} />
        </Row>
        <Card key="z" style={data.purchaseGrn.mainCardFirst} bodyStyle={{ padding: 0 }}>
          <Row style={data.purchaseGrn.headingRow}>
            <Col span={8}>
              <strong>Product</strong>
            </Col>
            <Col span={6} style={data.purchaseGrn.colAlign}>
              <strong>Quantity</strong>
            </Col>
            <Col span={5} style={data.purchaseGrn.colAlign}>
              <strong>Price</strong>
            </Col>
            <Col span={5} style={data.purchaseGrn.colAlign}>
              <strong>Line Total</strong>
            </Col>
          </Row>
          {supplierProductList.length > 0 ? (
            <div>
              <Scrollbars autoHide className="purchaseGrnScroll">
                {supplierProductList.map((item, index) => (
                  <Row style={{ padding: "10px 10px 0px" }}>
                    <Col span={8}>
                      <Row>
                        <Col span={4}>
                          <img onError={setDefaultImage} src={item.img} alt="" style={data.purchaseGrn.prodImgInTable} />
                        </Col>
                        <Col span={20} style={{ paddingTop: 5 }}>
                          <Row>
                            <p style={data.purchaseGrn.desc}>{item.productCode}</p>
                          </Row>
                          <Row>
                            <p style={data.purchaseGrn.prodTitle}>{item.productName}</p>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={6} style={{ textAlign: "center", alignSelf: "center" }}>
                      <div style={data.purchaseGrn.qtyInputsDiv}>
                        <MinusOutlined style={data.purchaseGrn.decrementInpt} type="minus" onClick={() => onFieldButton(index, "minus")} />
                        <Form.Item style={data.purchaseGrn.qtyInputFormitem}>
                          <Input
                            type="number"
                            min="0"
                            value={item.qty}
                            style={data.purchaseGrn.qtyInput}
                            onChange={(e) => {
                              onFieldButton(index, e.target.value);
                            }}
                          />
                        </Form.Item>
                        <PlusOutlined style={data.purchaseGrn.incrementInpt} type="plus" onClick={() => onFieldButton(index, "plus")} />
                      </div>
                    </Col>
                    <Col span={5} style={{ textAlign: "center", alignSelf: "center" }}>
                      <Input readOnly={true} style={data.purchaseGrn.priceTotalAmount} value={item.priceList} />
                    </Col>
                    <Col span={5} style={{ textAlign: "center", alignSelf: "center" }}>
                      <Input readOnly={true} style={data.purchaseGrn.priceTotalAmount} value={item.lineTotal} />
                    </Col>
                  </Row>
                ))}
              </Scrollbars>
            </div>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: "10% 0px" }} />
          )}
          <div style={{ display: supplierProductList.length > 0 ? "block" : "none" }}>
            <Row style={{ padding: 10 }}>
              <Col span={8}>
                <p style={data.purchaseGrn.totalProducts}>Total Products: {_.filter(supplierProductList, item => item.qty > 0)?.length || 0}</p>
              </Col>
              <Col span={6}>
                <p style={data.purchaseGrn.totalQty}>Total Qty: {_.sumBy(supplierProductList, "qty")} </p>
              </Col>
              <Col span={5} />
              <Col span={5}>
                <p style={data.purchaseGrn.totalValue}>Total: {_.sumBy(supplierProductList, "lineTotal")}</p>
              </Col>
            </Row>
            <Row style={{ padding: 10 }}>
              <Col span={21} />
              <Col span={3} style={{ float: "right" }}>
                <Button style={data.purchaseGrn.receiveBtn} onClick={handleReceive}>
                  <span style={data.purchaseGrn.receiveBtn.label}>RECEIVE</span>
                </Button>
              </Col>
            </Row>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default PurchaseGRN;
