import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, Button, Modal, Badge, Checkbox, Form, Input, Select } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroll-component";
import cwPosLogo from "../../../../assets/images/cwpos-green.png";
import MenuIcon from "../../../../assets/images/menu.png";
import Home from "../../../../assets/images/dashboard.png";
import TableReservedImg from "../../../../assets/images/table-reserved.svg";
import TableOccupiedImg from "../../../../assets/images/table-occupied.svg";
import TableOpenImg from "../../../../assets/images/table-open.svg";
import TableWaitingImg from "../../../../assets/images/table-waiting.svg";
import TableNoserviceImg from "../../../../assets/images/table-noservice.svg";
import data from "../../../../constants/restaurantPos.json";
import { clock, timeStamp, currentDay } from "../../../../utility/clock";

const { Option } = Select;

const TableManagement = (props) => {
  const history = useHistory();
  const dynamicHeight = 0.5625;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  // CLOCK BLOCK START
  const [displayClock, setDisplayClock] = useState(clock());
  useEffect(() => {
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  // CLOCK BLOCK END
  const currentDate = currentDay().charAt(0).toUpperCase() + currentDay().slice(1);
  const currentDateValue = currentDate.substring(0, 3);

  const statusCards = [
    {
      title: "All",
    },
    {
      title: "Reserved",
      status: <Badge color="#FE6D5C" text="Reserved" style={data.restaurantPos.tableManagement.statusTitle} />,
    },
    {
      title: "Occupied",
      status: <Badge color="#DA3131" text="Occupied" style={data.restaurantPos.tableManagement.statusTitle} />,
    },
    {
      title: "Open",
      status: <Badge color="#35A65B" text="Open" style={data.restaurantPos.tableManagement.statusTitle} />,
    },
    {
      title: "Waiting",
      status: <Badge color="#FFA85B" text="Waiting" style={data.restaurantPos.tableManagement.statusTitle} />,
    },
    {
      title: "No Service",
      status: <Badge color="#000000" text="No Service" style={data.restaurantPos.tableManagement.statusTitle} />,
    },
  ];

  const tableCards = [
    {
      title: "Table 1",
      tableImg: <img src={TableReservedImg} alt="restaurant table" />,
      status: <Badge color="#FE6D5C" />,
      merge: true
    },
    {
      title: "Table 2",
      tableImg: <img src={TableOccupiedImg} alt="restaurant table" />,
      status: <Badge color="#DA3131" />,
    },
    {
      title: "Table 3",
      tableImg: <img src={TableOpenImg} alt="restaurant table" />,
      status: <Badge color="#35A65B" />,
    },
    {
      title: "Table 4",
      tableImg: <img src={TableWaitingImg} alt="restaurant table" />,
      status: <Badge color="#FFA85B" />,
    },
    {
      title: "Table 5",
      tableImg: <img src={TableNoserviceImg} alt="restaurant table" />,
      status: <Badge color="#000000" />,
    },
    {
      title: "Table 6",
      tableImg: <img src={TableReservedImg} alt="restaurant table" />,
      status: <Badge color="#FE6D5C" />,
    },
    {
      title: "Table 7",
      tableImg: <img src={TableOccupiedImg} alt="restaurant table" />,
      status: <Badge color="#DA3131" />,
    },
    {
      title: "Table 8",
      tableImg: <img src={TableOpenImg} alt="restaurant table" />,
      status: <Badge color="#35A65B" />,
    },
    {
      title: "Table 9",
      tableImg: <img src={TableWaitingImg} alt="restaurant table" />,
      status: <Badge color="#FFA85B" />,
    },
    {
      title: "Table 10",
      tableImg: <img src={TableNoserviceImg} alt="restaurant table" />,
      status: <Badge color="#000000" />,
    },
    {
      title: "Table 11",
      tableImg: <img src={TableReservedImg} alt="restaurant table" />,
      status: <Badge color="#FE6D5C" />,
    },
    {
      title: "Table 12",
      tableImg: <img src={TableOccupiedImg} alt="restaurant table" />,
      status: <Badge color="#DA3131" />,
    },
    {
      title: "Table 13",
      tableImg: <img src={TableOpenImg} alt="restaurant table" />,
      status: <Badge color="#35A65B" />,
    },
    {
      title: "Table 14",
      tableImg: <img src={TableWaitingImg} alt="restaurant table" />,
      status: <Badge color="#FFA85B" />,
    },
    {
      title: "Table 15",
      tableImg: <img src={TableNoserviceImg} alt="restaurant table" />,
      status: <Badge color="#000000" />,
    },
    {
      title: "Table 16",
      tableImg: <img src={TableReservedImg} alt="restaurant table" />,
      status: <Badge color="#FE6D5C" />,
    },
    {
      title: "Table 17",
      tableImg: <img src={TableOccupiedImg} alt="restaurant table" />,
      status: <Badge color="#DA3131" />,
    },
    {
      title: "Table 18",
      tableImg: <img src={TableOpenImg} alt="restaurant table" />,
      status: <Badge color="#35A65B" />,
    },
    {
      title: "Table 19",
      tableImg: <img src={TableWaitingImg} alt="restaurant table" />,
      status: <Badge color="#FFA85B" />,
    },
    {
      title: "Table 20",
      tableImg: <img src={TableNoserviceImg} alt="restaurant table" />,
      status: <Badge color="#000000" />,
    },
    {
      title: "Table 21",
      tableImg: <img src={TableReservedImg} alt="restaurant table" />,
      status: <Badge color="#FE6D5C" />,
    },
    {
      title: "Table 22",
      tableImg: <img src={TableOccupiedImg} alt="restaurant table" />,
      status: <Badge color="#DA3131" />,
    },
    {
      title: "Table 23",
      tableImg: <img src={TableOpenImg} alt="restaurant table" />,
      status: <Badge color="#35A65B" />,
    },
    {
      title: "Table 24",
      tableImg: <img src={TableWaitingImg} alt="restaurant table" />,
      status: <Badge color="#FFA85B" />,
    },
    {
      title: "Table 25",
      tableImg: <img src={TableNoserviceImg} alt="restaurant table" />,
      status: <Badge color="#000000" />,
    },
    {
      title: "Table 26",
      tableImg: <img src={TableReservedImg} alt="restaurant table" />,
      status: <Badge color="#FE6D5C" />,
    },
    {
      title: "Table 27",
      tableImg: <img src={TableOccupiedImg} alt="restaurant table" />,
      status: <Badge color="#DA3131" />,
    },
    {
      title: "Table 28",
      tableImg: <img src={TableOpenImg} alt="restaurant table" />,
      status: <Badge color="#35A65B" />,
    },
    {
      title: "Table 29",
      tableImg: <img src={TableWaitingImg} alt="restaurant table" />,
      status: <Badge color="#FFA85B" />,
    },
    {
      title: "Table 30",
      tableImg: <img src={TableNoserviceImg} alt="restaurant table" />,
      status: <Badge color="#000000" />,
    },
  ];

  const reservedTablesList = [
    {
      table: "Table 1",
      custName: "Ajay Kumar",
      mobile: "+91 9067687568",
      time: "13:35",
    },
    {
      table: "Table 2",
      custName: "Sudheer",
      mobile: "+91 9067687568",
      time: "13:35",
    },
    {
      table: "Table 3",
      custName: "Sai Chandana",
      mobile: "+91 9067687568",
      time: "13:35",
    },
    {
      table: "Table 4",
      custName: "Avinash",
      mobile: "+91 9067687568",
      time: "13:35",
    },
    {
      table: "Table 5",
      custName: "Vamsi",
      mobile: "+91 9067687568",
      time: "13:35",
    },
    {
      table: "Table 6",
      custName: "Seshasai",
      mobile: "+91 9067687568",
      time: "13:35",
    },
    {
      table: "Table 7",
      custName: "Sai Prakash",
      mobile: "+91 9067687568",
      time: "13:35",
    },
    {
      table: "Table 8",
      custName: "Sirishnadh",
      mobile: "+91 9067687568",
      time: "13:35",
    },
  ];

  const [displayMergeTablesPopup, setDisplayMergeTablesPopup] = useState(false);
  const [mergeTablesSelection, setMergeTablesSelection] = useState(false);

  const handleMergeTables = () => {
    setMergeTablesSelection(true);
    // setDisplayMergeTablesPopup(true);
  };

  const handleTable = (record) => {
    if (mergeTablesSelection === true) {
      history.push("/table-management");
    } else {
      localStorage.setItem("tableName", record?.title);
      history.push({
        pathname: "/pos",
        state: { table: record?.title || "" },
      });
    }
  };

  const [form] = Form.useForm();
  const [displayReserveTablesPopup, setDisplayReserveTablesPopup] = useState(false);

  const handleReserveTable = () => {
    setDisplayReserveTablesPopup(true);
  };

  const handleReserveTableDetails = (values) => {
    setDisplayReserveTablesPopup(false);
  };

  const tableList = tableCards.map((tableObj) => (
    <Option key={tableObj.title} value={tableObj.title}>
      {tableObj.title}
    </Option>
  ));

  const handleRedirectDash = () => {
    history.push("/dashboard");
  };

  return (
    <div className="main" style={data.restaurantPos.main}>
      <Row
        style={{
          height: `${3.7 * dynamicHeight}vw`,
          padding: data.restaurantPos.header.padding,
        }}
      >
        <Col span={1}>
          <img src={MenuIcon} style={data.restaurantPos.header.menuIcon} alt="" />
        </Col>
        <Col span={5}>
          <label style={data.restaurantPos.header.posHeadLabel}>{tillData.tillAccess.csBunit.name}</label>
        </Col>
        <Col span={10}>
          <label style={data.restaurantPos.header.timeFmt}>{tillData.tillAccess.cwrTill.till}</label>
        </Col>
        <Col span={4} style={data.restaurantPos.header.right}>
          <label style={data.restaurantPos.header.time}>{displayClock}</label>
        </Col>
        <Col span={4} style={data.restaurantPos.header.right}>
          <img onClick={handleRedirectDash} src={cwPosLogo} style={data.restaurantPos.header.posLogoIcon} alt="" />
        </Col>
      </Row>
      <Row style={data.restaurantPos.tableManagement.mainRowTable} className="mainRow">
        <Col span={17} style={{ paddingRight: 5 }}>
          <Card style={data.restaurantPos.tableManagement.mainCardTable} bodyStyle={{ padding: 0 }}>
            <Row style={data.restaurantPos.tableManagement.subHeadingRow}>
              <Col span={18} style={data.restaurantPos.tableManagement.leftAlign}>
                <strong>Table Management</strong>
              </Col>
              <Col span={6} style={data.restaurantPos.tableManagement.rightAlign}>
                <img src={Home} alt="dashboard" className="productSpecImg" />
              </Col>
            </Row>
            <div style={data.restaurantPos.tableManagement.contentRow}>
              <Row gutter={[8, 0]}>
                <Col span={16}>
                  <Row gutter={[8, 0]}>
                    {statusCards.map((item) => (
                      <Col span={4}>
                        <Card bodyStyle={{ padding: 5 }} style={{ boxShadow: "1px 1px 6px -3px #000000" }}>
                          <div style={data.restaurantPos.tableManagement.statusDiv}>
                            {item.status ? <span>{item.status}</span> : <span style={data.restaurantPos.tableManagement.statusTitle}>{item.title} </span>}
                          </div>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col span={8}>
                  <Row gutter={[8, 0]}>
                    <Col span={8}>
                      <Button style={data.restaurantPos.tableManagement.tablebtns} onClick={handleReserveTable}>
                        <div style={data.restaurantPos.tableManagement.statusDiv}>
                          <span style={data.restaurantPos.tableManagement.buttonTitle}>Reserve Table</span>{" "}
                        </div>
                      </Button>
                    </Col>
                    <Col span={8}>
                      <Button style={data.restaurantPos.tableManagement.tablebtns} onClick={handleMergeTables}>
                        <div style={data.restaurantPos.tableManagement.statusDiv}>
                          <span style={data.restaurantPos.tableManagement.buttonTitle}>Merge Tables</span>{" "}
                        </div>
                      </Button>
                    </Col>
                    <Col span={8}>
                      <Button style={data.restaurantPos.tableManagement.tablebtns}>
                        <div style={data.restaurantPos.tableManagement.statusDiv}>
                          <span style={data.restaurantPos.tableManagement.buttonTitle}>Split Table</span>{" "}
                        </div>
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Scrollbars autoHide className="tablesScroll">
                <InfiniteScroll
                  dataLength={tableCards.length}
                  hasMore={true}
                  endMessage={
                    <p className="center">
                      <b>Yay! you have seen it all</b>
                    </p>
                  }
                  style={{ overflowX: "hidden" }}
                >
                  <Row gutter={[4, 4]} style={{ marginTop: "0.5%" }}>
                    {tableCards.map((item) => (
                      <Col span={item.merge ? 8 : 4}>
                        <Card bodyStyle={{ padding: "0px 5px 10px" }} style={{ borderRadius: "5px" }} onClick={() => handleTable(item)}>
                          <Row style={{ justifyContent: "end" }}>
                            <Col span={12}>
                              <div
                                style={{
                                  display: mergeTablesSelection === true ? "block" : "none",
                                }}
                              >
                                <Checkbox></Checkbox>
                              </div>
                            </Col>
                            <Col span={12}>
                              <div style={data.restaurantPos.tableManagement.rightAlign}>{item.status}</div>
                            </Col>
                          </Row>
                          <Row style={{ justifyContent: "center" }}>
                            <div style={data.restaurantPos.tableManagement.tableImg}>{item.tableImg}</div>
                          </Row>
                          <Row style={{ justifyContent: "center" }}>
                            <div style={data.restaurantPos.tableManagement.tableTitle}>{item.title}</div>
                          </Row>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </InfiniteScroll>
              </Scrollbars>
            </div>
          </Card>
        </Col>
        <Col span={7} style={data.restaurantPos.content.orderCart.colStyles}>
          <Row style={data.restaurantPos.content.orderCart.firstRow}>
            <Col span={24}>
              <p style={data.restaurantPos.content.orderCart.orderListText}>Reserved List</p>
            </Col>
          </Row>
          <Row style={data.restaurantPos.content.orderCart.secondRow}>
            <Col span={12}>
              <p style={data.restaurantPos.tableManagement.totalTables}>Total Tables : {reservedTablesList.length}</p>
            </Col>
            <Col span={12}>
              <p style={data.restaurantPos.content.orderCart.custTimeDetails}>{`${currentDateValue}, ${timeStamp()}`}</p>
            </Col>
          </Row>
          <Row>
            <Scrollbars autoHide className="reservedTableScroll">
              <Row style={{ padding: 10 }}>
                <Col span={5}>
                  <p style={data.restaurantPos.tableManagement.rightsideCols}>Table</p>
                </Col>
                <Col span={9}>
                  <p style={data.restaurantPos.tableManagement.rightsideCols}>Customer Name</p>
                </Col>
                <Col span={7}>
                  <p style={data.restaurantPos.tableManagement.rightsideCols}>Mobile</p>
                </Col>
                <Col span={3}>
                  <p style={data.restaurantPos.tableManagement.rightsideColsTime}>Time</p>
                </Col>
              </Row>
              {reservedTablesList.map((item) => (
                <Row className="reservedTableRow" style={{ padding: 10 }}>
                  <Col span={5}>
                    <p style={data.restaurantPos.tableManagement.rightsideData}>{item.table}</p>
                  </Col>
                  <Col span={9}>
                    <p style={data.restaurantPos.tableManagement.rightsideData}>{item.custName}</p>
                  </Col>
                  <Col span={7}>
                    <p style={data.restaurantPos.tableManagement.rightsideData}>{item.mobile}</p>
                  </Col>
                  <Col span={3}>
                    <p style={data.restaurantPos.tableManagement.rightsideDataTime}>{item.time}</p>
                  </Col>
                </Row>
              ))}
            </Scrollbars>
          </Row>
        </Col>
      </Row>

      {/* MERGE TABLES POPUP STARTS */}
      <Modal visible={displayMergeTablesPopup} closable={false} footer={null} bodyStyle={{ padding: 20 }} width="30%" style={{ top: 200 }}>
        <div>
          <Row style={{ paddingTop: 10 }}>
            <Col span={24}>
              <p style={data.restaurantPos.tableManagement.mergeTables.modalTitle}>Are you sure want to Merge the Tables?</p>
            </Col>
          </Row>
          <Row style={data.restaurantPos.tableManagement.mergeTables.yesCancelBtns}>
            <Button
              size="medium"
              className="customerDetailsSubmitBtnBtn"
              style={data.restaurantPos.tableManagement.mergeTables.mergeTablesSubmitBtn}
              htmlType="submit"
              onClick={() => setDisplayMergeTablesPopup(false)}
            >
              Yes
            </Button>
            <Button
              size="medium"
              className="customerDetailsCancelBtn"
              style={data.restaurantPos.tableManagement.mergeTables.mergeTablesCancelBtn}
              onClick={() => setDisplayMergeTablesPopup(false)}
            >
              No
            </Button>
          </Row>
        </div>
      </Modal>
      {/* MERGE TABLES POPUP ENDS */}

      {/* RESERVE TABLE MODAL BEGIN */}
      <Modal
        title={
          <div>
            <Row>
              <Col span={24}>
                <p style={data.restaurantPos.tableManagement.reserveTableModal.modalTitle}>Reserve Table</p>
              </Col>
            </Row>
          </div>
        }
        visible={displayReserveTablesPopup}
        onCancel={null}
        footer={null}
        width="25%"
        closable={false}
        bodyStyle={{ padding: "10px 20px" }}
        style={{ top: 50 }}
      >
        <Form key={"reserveTable"} form={form} name="reserveTable" onFinish={handleReserveTableDetails}>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Name</label>
              <Form.Item name="name" style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                <Input placeholder="Enter Name" style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Contact Number</label>
              <Form.Item name="mobile" style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                <Input placeholder="Enter Contact Number" style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Time</label>
              <Form.Item name="time" style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                <Input placeholder="Enter Time" style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>No. of persons</label>
              <Form.Item name="noOfPersons" style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                <Input placeholder="Enter No of Persons" style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <label style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsField}>Table Number</label>
              <Form.Item name="tableNum" style={data.restaurantPos.tableManagement.reserveTableModal.formItem}>
                <Select
                  style={data.restaurantPos.tableManagement.reserveTableModal.tableDetailsInput}
                  showSearch
                  placeholder="Select Table"
                  optionFilterProp="children"
                  // onChange={onChange}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {tableList}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row style={data.restaurantPos.tableManagement.reserveTableModal.reserveCancelBtns}>
            <Button
              size="medium"
              className="customerDetailsSubmitBtnBtn"
              style={data.restaurantPos.tableManagement.reserveTableModal.reserveTablesSubmitBtn}
              htmlType="submit"
              // onClick={() => setDisplayReserveTablesPopup(false)}
            >
              Reserve
            </Button>
            <Button
              size="medium"
              className="customerDetailsCancelBtn"
              style={data.restaurantPos.tableManagement.reserveTableModal.reserveTablesCancelBtn}
              onClick={() => setDisplayReserveTablesPopup(false)}
            >
              Cancel
            </Button>
          </Row>
        </Form>
      </Modal>
      {/* RESERVE TABLE MODAL END */}

    </div>
  );
};
export default TableManagement;
