import React, { Fragment } from "react";
import { Row, Col, Card, Button, Input, Radio, Modal, Form } from "antd";
import { SearchOutlined, ArrowLeftOutlined, EditFilled, TagsOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import Delete from "../../../assets/images/delete.png";
import CashLogo from "../../../assets/images/cash.png";
import CashLogoWhite from "../../../assets/images/cashWhite.svg";
import closeIcon from "../../../assets/images/closeicon.png";
import Backspace from "../../../assets/images/backspac.png";
import AspectRatio from "react-aspect-ratio";
import "react-aspect-ratio/aspect-ratio.css";
import { useWindowDimensions } from "../../../lib/useViewport";
import "../../style.css";
import "../../globalStyles.css";
import data from "../../../constants/retailPos.json";

const PointOfSaleCoreModals = (props) => {
  const { width } = useWindowDimensions();
  const {
    displayCustomerSearch,
    closeCustomerSearch,
    setCustomerSearchType,
    customerSearchType,
    handleCustomerSearch,
    customerSearchInput,
    setCustomerSearchResults,
    setCustomerSearchInput,
    customerSearchResults,
    selectCustomer,
    showEditOldCustomerFields,
    showAddNewCustomerFields,
    setDisplayCustomerSearch,
    setDisplayAddNewCustomer,
    displayAddNewCustomer,
    form,
    addNewCustomer,
    setDisplayEditOldCustomer,
    displayEditOldCustomer,
    editOldCustomer,
    paymentModal,
    tillDataPaymentMethods,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    cart,
    requestPayment,
    setCouponModalVisible,
    closePaymentModal,
    paymentModalLoyalityMessages,
    amount,
    onChangeAmount,
    processOrder,
    handleAmount,
    currencyType,
  } = props;

  return (
    <Fragment>
      {/* CUSTOMER SEARCH MODAL BEGIN*/}
      <Modal
        visible={displayCustomerSearch}
        onCancel={closeCustomerSearch}
        closable={false}
        footer={null}
        // width="40%"
        bodyStyle={{ padding: 15 }}
      >
        <Radio.Group
          size="large"
          onChange={(e) => {
            setCustomerSearchType(e.target.value);
          }}
          value={customerSearchType}
          style={{ width: "100%" }}
        >
          <Row gutter={[0, 8]}>
            <Col xs={24} sm={10} md={10} lg={12} xl={12} xxl={12}>
              <Radio value="searchKey" style={data.posScreenStyles.customerModal.radioLblCus} disabled={false}>
                Search By Customer
              </Radio>
            </Col>
            <Col xs={24} sm={14} md={14} lg={12} xl={12} xxl={12}>
              <Radio value="mobile" style={data.posScreenStyles.customerModal.radioLblCus}>
                Search By Mobile Number
              </Radio>
            </Col>
          </Row>
        </Radio.Group>
        <Input
          placeholder="Search"
          size="large"
          style={data.posScreenStyles.customerModal.customerSearchInput}
          className="customerSearchInput"
          suffix={<SearchOutlined onClick={handleCustomerSearch} style={data.posScreenStyles.customerModal.searchIcon} />}
          value={customerSearchInput}
          onPressEnter={handleCustomerSearch}
          onChange={(e) => {
            e.target.value === ""
              ? (() => {
                  setCustomerSearchResults();
                  setCustomerSearchInput(e.target.value);
                })()
              : setCustomerSearchInput(e.target.value);
          }}
        />
        <Row style={{ paddingTop: 10 }}>
          <Col span={24}>
            {customerSearchResults === undefined ? (
              <div style={data.posScreenStyles.header.left}>
                <Card bordered style={data.posScreenStyles.customerModal.customerSearchList} />
              </div>
            ) : customerSearchResults !== undefined && customerSearchResults.length > 0 ? (
              <div style={data.posScreenStyles.header.left}>
                <Card bordered style={data.posScreenStyles.customerModal.customerSearchList} bodyStyle={{ padding: 10 }}>
                  {customerSearchResults.map((customerSearchResult, index) => (
                    <Card key={`${index}`} style={data.posScreenStyles.customerModal.customerDetailsCard} bodyStyle={{ padding: 10 }}>
                      <Row>
                        <Col span={22} onClick={() => selectCustomer(index)}>
                          <p style={data.posScreenStyles.customerModal.customerName}>{customerSearchResult.name}</p>
                        </Col>
                        <Col span={2}>
                          <EditFilled style={data.posScreenStyles.customerModal.dropdownCircleIcon} onClick={() => showEditOldCustomerFields(customerSearchResult)} />
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: 10 }} onClick={() => selectCustomer(index)}>
                        <Col span={24}>
                          <p style={data.posScreenStyles.customerModal.customerStatus}>{customerSearchResult.mobileNo}</p>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                </Card>
              </div>
            ) : (
              <div style={data.posScreenStyles.header.left}>
                <Card bordered style={data.posScreenStyles.customerModal.customerSearchList}>
                  <Row>
                    <p style={data.posScreenStyles.customerModal.noResultsText}>No results found</p>
                  </Row>
                  <Row>
                    <p style={data.posScreenStyles.customerModal.addNewCustomer} onClick={showAddNewCustomerFields}>
                      + Add as a new customer
                    </p>
                  </Row>
                </Card>
              </div>
            )}
          </Col>
        </Row>
      </Modal>
      {/* CUSTOMER SEARCH MODAL END*/}

      {/* ADD NEW CUSTOMER MODAL BEGIN*/}
      <Modal
        title={
          <div>
            <Row>
              <Col span={2}>
                <ArrowLeftOutlined
                  style={data.posScreenStyles.addEditCustomerModal.arrowLeftIcon}
                  onClick={() => {
                    setDisplayCustomerSearch(true);
                    setDisplayAddNewCustomer(false);
                    setCustomerSearchInput("");
                    setCustomerSearchResults();
                  }}
                />
              </Col>
              <Col span={22}>
                <p style={data.posScreenStyles.addEditCustomerModal.addNewCustLabel}>Add New Customer</p>
              </Col>
            </Row>
          </div>
        }
        visible={displayAddNewCustomer}
        onCancel={null}
        footer={null}
        // width="40%"
        closable={false}
        bodyStyle={{ padding: 20 }}
      >
        {displayAddNewCustomer ? (
          <Form key={"addCustomer"} form={form} name="addCustomer" onFinish={addNewCustomer}>
            <Row gutter={[16, 16]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Name</label>
                <Form.Item name="name" rules={[{ required: true, message: "Please Input Name" }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                  <Input placeholder="Enter Name" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Mobile Number</label>
                <Form.Item
                  name="mobile"
                  rules={[
                    {
                      required: true,
                      pattern: new RegExp(/^[6-9][0-9]{9}$/),
                      message: "Please Input Valid Mobile Number",
                    },
                  ]}
                  style={data.posScreenStyles.addEditCustomerModal.formItem}
                >
                  <Input placeholder="Enter Mobile Number" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                {/* <label className="cutomerDetailsField">Date of Birth</label>
                <Form.Item name="dob" rules={[{ required: true, message: 'Please Input Date of Birth' }]}>
                  <DatePicker
                    placeholder="DD/MM/YYYY"
                    className="cutomerDobInput"
                    format="DD/MM/YYYY"
                  />
                </Form.Item> */}
                <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Email</label>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: false,
                      pattern: new RegExp(/\S+@\S+\.\S+/),
                      message: "Please Input Valid Email",
                    },
                  ]}
                  style={data.posScreenStyles.addEditCustomerModal.formItem}
                >
                  <Input placeholder="Enter Email" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Pincode</label>
                <Form.Item
                  name="pincode"
                  rules={[
                    {
                      required: false,
                      pattern: new RegExp(/\d+/g),
                      message: "Please Input Pincode",
                    },
                  ]}
                  style={data.posScreenStyles.addEditCustomerModal.formItem}
                >
                  <Input placeholder="Enter Pincode" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={data.posScreenStyles.addEditCustomerModal.customerDetailsBtns}>
              <Button
                size="large"
                className="customerDetailsCancelBtn"
                style={data.posScreenStyles.addEditCustomerModal.customerDetailsCancelBtn}
                onClick={() => {
                  form.resetFields();
                  closeCustomerSearch();
                  setDisplayAddNewCustomer(false);
                }}
              >
                Cancel
              </Button>
              <Button size="large" className="customerDetailsSubmitBtnBtn" style={data.posScreenStyles.addEditCustomerModal.customerDetailsSubmitBtn} htmlType="submit">
                Submit
              </Button>
            </Row>
          </Form>
        ) : (
          ""
        )}
      </Modal>
      {/* ADD NEW CUSTOMER MODAL END*/}

      {/* EDIT CUSTOMER MODAL BEGIN*/}
      <Modal
        title={
          <div>
            <Row>
              <Col span={2}>
                <ArrowLeftOutlined
                  style={data.posScreenStyles.addEditCustomerModal.arrowLeftIcon}
                  onClick={() => {
                    setDisplayCustomerSearch(true);
                    setDisplayEditOldCustomer(false);
                  }}
                />
              </Col>
              <Col span={22}>
                <p style={data.posScreenStyles.addEditCustomerModal.addNewCustLabel}>Edit Customer</p>
              </Col>
            </Row>
          </div>
        }
        visible={displayEditOldCustomer}
        onCancel={null}
        footer={null}
        // width="40%"
        closable={false}
        bodyStyle={{ padding: 20 }}
      >
        {displayEditOldCustomer ? (
          <Form key={"editCustomer"} form={form} name="editCustomer" onFinish={editOldCustomer}>
            <Row gutter={[16, 16]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Name</label>
                <Form.Item name="editName" rules={[{ required: true, message: "Please Input Name" }]} style={data.posScreenStyles.addEditCustomerModal.formItem}>
                  <Input placeholder="Enter Name" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Mobile Number</label>
                <Form.Item
                  name="editMobile"
                  rules={[
                    {
                      required: true,
                      pattern: new RegExp(/^[6-9][0-9]{9}$/),
                      message: "Please Input Valid Mobile Number",
                    },
                  ]}
                  style={data.posScreenStyles.addEditCustomerModal.formItem}
                >
                  <Input placeholder="Enter Mobile Number" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                {/* <label className="cutomerDetailsField">Date of Birth</label>
                <Form.Item name="editDob" rules={[{ required: true, message: 'Please Input Date of Birth' }]}>
                  <DatePicker
                    placeholder="DD/MM/YYYY"
                    className="cutomerDobInput"
                    format="DD/MM/YYYY"
                  />
                </Form.Item> */}
                <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Email</label>
                <Form.Item
                  name="editEmail"
                  rules={[
                    {
                      required: false,
                      pattern: new RegExp(/\S+@\S+\.\S+/),
                      message: "Please Input Valid Email",
                    },
                  ]}
                  style={data.posScreenStyles.addEditCustomerModal.formItem}
                >
                  <Input placeholder="Enter Email" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
                <label style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsField}>Pincode</label>
                <Form.Item
                  name="editPincode"
                  rules={[
                    {
                      required: false,
                      pattern: new RegExp(/\d+/g),
                      message: "Please Input Pincode",
                    },
                  ]}
                  style={data.posScreenStyles.addEditCustomerModal.formItem}
                >
                  <Input placeholder="Enter Pincode" style={data.posScreenStyles.addEditCustomerModal.cutomerDetailsInput} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={data.posScreenStyles.addEditCustomerModal.customerDetailsBtns}>
              <Button
                size="large"
                className="customerDetailsCancelBtn"
                style={data.posScreenStyles.addEditCustomerModal.customerDetailsCancelBtn}
                onClick={() => {
                  form.resetFields();
                  closeCustomerSearch();
                  setDisplayEditOldCustomer(false);
                }}
              >
                Cancel
              </Button>
              <Button size="large" className="customerDetailsSubmitBtnBtn" style={data.posScreenStyles.addEditCustomerModal.customerDetailsSubmitBtn} htmlType="submit">
                Submit
              </Button>
            </Row>
          </Form>
        ) : (
          ""
        )}
      </Modal>
      {/* EDIT CUSTOMER MODAL END*/}

      {/* PAYMENT MODAL BEGIN*/}
      <Modal visible={paymentModal} closable={false} footer={null} width="60%" centered bodyStyle={{ padding: 0 }}>
        <Card bodyStyle={{ padding: 15 }}>
          <Row gutter={[10, 0]}>
            <Col span={12}>
              <Card style={data.posScreenStyles.paymentModal.noBorderCard} bodyStyle={{ padding: "10px" }}>
                <Row>
                  <Col span={24}>
                    <Scrollbars autoHide style={data.posScreenStyles.paymentModal.scrollAuto}>
                      {/* <Card
                          className="no-border-card"
                          bodyStyle={{
                            padding: "10px",
                            height: "20vh",
                            backgroundColor: "#F5F5F5",
                            borderRadius: "6px 6px 0px 0px",
                          }}
                        >
                          {cart.payments.map((paymentM) => {
                            return (
                              <Row key={paymentM.name}>
                                <Col span={19}>
                                  {" "}
                                  <span className="paymtName">{paymentM.name}</span>
                                </Col>
                                <Col span={3}>
                                  {" "}
                                  <span className="paymtAmt">{paymentM.amount}</span>
                                </Col>
                                <Col span={2}>
                                  <span style={{ float: "right" }}>
                                    <img src={Delete} onClick={() => requestPayment(paymentM, -paymentM.amount, "allow")} className="delete-icon" alt="" />
                                  </span>
                                </Col>
                              </Row>
                            );
                          })}
                        </Card> */}
                    </Scrollbars>
                  </Col>
                </Row>
                {/*  <p className="para-margin" /> */}
                <Row>
                  <Col span={24}>
                    <Scrollbars autoHide style={data.posScreenStyles.paymentModal.scrollValue}>
                      {tillDataPaymentMethods.map((pm) => {
                        return (
                          <Card
                            key={pm.finPaymentmethodId}
                            onClick={() => {
                              setSelectedPaymentMethod(pm);
                            }}
                            style={data.posScreenStyles.paymentModal.noBorderCard}
                            bodyStyle={{ padding: "0px", marginTop: "15px" }}
                          >
                            <Row>
                              <Col span={24}>
                                <Card
                                  style={
                                    pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId
                                      ? data.posScreenStyles.paymentModal.selectedPaymentType
                                      : data.posScreenStyles.paymentModal.paymtCard
                                  }
                                  // className={
                                  //   pm.finPaymentmethodId ===
                                  //   selectedPaymentMethod.finPaymentmethodId
                                  //     ? "selectedPaymentType"
                                  //     : "paymt-card"
                                  // }
                                  bodyStyle={{ padding: "8px" }}
                                >
                                  <Row>
                                    <Col span={12}>
                                      <img
                                        src={pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? CashLogoWhite : CashLogo}
                                        style={data.posScreenStyles.paymentModal.wfifty}
                                        alt=""
                                      />{" "}
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <span
                                        style={
                                          pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId
                                            ? data.posScreenStyles.paymentModal.selectedPaymentTypeName
                                            : data.posScreenStyles.paymentModal.paymtName
                                        }
                                        // className={
                                        //   pm.finPaymentmethodId ===
                                        //   selectedPaymentMethod.finPaymentmethodId
                                        //     ? "selectedPaymentTypeName"
                                        //     : "paymt-name"
                                        // }
                                      >
                                        {pm.name}
                                      </span>
                                    </Col>
                                    <Col span={12}>
                                      {(() => {
                                        const iN = cart.payments.findIndex((paymentMade) => paymentMade.name === pm.name);
                                        if (iN >= 0) {
                                          return (
                                            <Row>
                                              <Col span={20}>
                                                <span
                                                  // className={
                                                  //   pm.finPaymentmethodId ===
                                                  //   selectedPaymentMethod.finPaymentmethodId
                                                  //     ? "selectedPaymentTypeName"
                                                  //     : "paymt-name"
                                                  // }
                                                  // style={{ float: "right" }}
                                                  style={
                                                    pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId
                                                      ? data.posScreenStyles.paymentModal.selectedPaymentTypeName
                                                      : data.posScreenStyles.paymentModal.paymtName
                                                  }
                                                >
                                                  {currencyType.currSymbolLeft} {cart.payments[iN].amount}
                                                </span>
                                              </Col>
                                              <Col span={4}>
                                                <span style={data.posScreenStyles.paymentModal.deleteIconSpan}>
                                                  <img
                                                    src={Delete}
                                                    onClick={() => requestPayment(cart.payments[iN], -cart.payments[iN].amount, "allow")}
                                                    style={data.posScreenStyles.paymentModal.deleteIcon}
                                                    alt=""
                                                  />
                                                </span>
                                              </Col>
                                            </Row>
                                          );
                                        }
                                      })()}
                                    </Col>
                                  </Row>
                                </Card>
                              </Col>
                            </Row>
                          </Card>
                        );
                      })}
                    </Scrollbars>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 0 }}>
                  <Button
                    type="dashed"
                    size="large"
                    disabled={cart.mPricingCouponId ? true : false}
                    onClick={() => setCouponModalVisible(true)}
                    className="add-payment-btn-white"
                    style={data.posScreenStyles.paymentModal.addpaymentbtnWhite}
                  >
                    <TagsOutlined /> Apply Coupon
                  </Button>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card
                style={data.posScreenStyles.paymentModal.noBorderCard}
                bodyStyle={{
                  padding: "10px",
                  /*  backgroundColor: "#F5F5F5",
                    borderRadius: "0px 0px 6px 6px", */
                }}
              >
                <Row>
                  <Col offset={12} span={12}>
                    <span style={data.posScreenStyles.paymentModal.deleteIconSpan}>
                      <img src={closeIcon} alt="close" onClick={closePaymentModal} />
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col offset={12} span={12}>
                    <br />
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    {" "}
                    <span style={data.posScreenStyles.paymentModal.cartTotalMethod}>{parseFloat(cart.total) >= parseFloat(cart.paid) ? "To Pay" : "Change"}</span>
                  </Col>
                  <Col span={12}>
                    <span style={data.posScreenStyles.paymentModal.cartTotalValue}>
                      {currencyType.currSymbolLeft}
                      &nbsp;
                      {parseFloat(cart.total) >= parseFloat(cart.paid) ? parseFloat(cart.total - cart.paid).toFixed(2) : parseFloat(cart.paid - cart.total).toFixed(2)}
                    </span>
                  </Col>
                </Row>
              </Card>
              <Card style={data.posScreenStyles.paymentModal.noBorderCard} bodyStyle={{ padding: "10px" }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <h3 style={data.posScreenStyles.paymentModal.topayEntrAmt}>{paymentModalLoyalityMessages.inputMessage}</h3>
                  </Col>
                  {/* <p className="para-margin" /> */}
                  {/* <Row> */}
                  <Col span={24}>
                    <Input
                      value={amount}
                      onChange={onChangeAmount}
                      onPressEnter={parseFloat(cart.total) > parseFloat(cart.paid) ? () => requestPayment(selectedPaymentMethod, amount) : () => processOrder()}
                      style={data.posScreenStyles.paymentModal.topayInput}
                      size="large"
                    />
                  </Col>
                  {/* </Row> */}
                  {/* <p className="para-margin" /> */}
                  {/* <Row style={{ paddingTop: 10 }}> */}
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("1")}>
                        1
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("2")}>
                        2
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("3")}>
                        3
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("x")}>
                        <img src={Backspace} alt="back space" width={width <= 1024 ? 20 : 30} height={width <= 1024 ? 15 : 20} />
                      </button>
                    </AspectRatio>
                  </Col>
                  {/* </Row> */}
                  {/* <Row style={{ paddingTop: 15 }}> */}
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("4")}>
                        4
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("5")}>
                        5
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("6")}>
                        6
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount(".")}>
                        .
                      </button>
                    </AspectRatio>
                  </Col>
                  {/* </Row> */}
                  {/* <Row style={{ paddingTop: 15 }}> */}
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("7")}>
                        7
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("8")}>
                        8
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("9")}>
                        9
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleAmount("0")}>
                        0
                      </button>
                    </AspectRatio>
                  </Col>
                  {/* </Row> */}
                  {/* <p className="para-margin" /> */}
                  {/* <Row style={{ paddingTop: 18 }}> */}
                  <Button
                    size="large"
                    onClick={parseFloat(cart.total) > parseFloat(cart.paid) ? () => requestPayment(selectedPaymentMethod, amount) : () => processOrder()}
                    style={data.posScreenStyles.paymentModal.addPaymentBtn}
                  >
                    {parseFloat(cart.total) !== 0 ? (parseFloat(cart.total) > parseFloat(cart.paid) ? paymentModalLoyalityMessages.buttonText : "Order") : "Payment Not Allowed !"}
                  </Button>
                </Row>
              </Card>
            </Col>
          </Row>
        </Card>
      </Modal>
      {/* PAYMENT MODAL END*/}
    </Fragment>
  );
};

export default PointOfSaleCoreModals;
