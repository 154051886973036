import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, Modal, Radio, DatePicker, Upload, message } from "antd";
import { useHistory } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { clock } from "../../utility/clock";
import MenuIcon from "../../assets/images/menu.png";
// import logoIcon from "../../assets/images/cwpos-blue.png";
import POSLogo from "../../assets/images/cwPosLogo.png";
import "antd/dist/antd.css";
import "./styles.css";
import db from "../../database";

const FarmerRegistration = () => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const history = useHistory();

  // CLOCK BLOCK START
  const [displayClock, setDisplayClock] = useState(clock());
  useEffect(() => {
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  // CLOCK BLOCK END

  const handlePosLogo = () => {
    history.push("/dashboard");
  };

  // Form Values //

  const [registrationNumber, setRegistrationNumber] = useState(1000);
  const [form] = Form.useForm();

  useEffect(() => {
    let regNum = localStorage.getItem("lastUpdatedRegNum");
    if (regNum) {
      setRegistrationNumber(Number(regNum) + 1);
      form.setFieldsValue({
        registrationNum: registrationNumber,
      });
    } else {
      setRegistrationNumber((regNumber) => regNumber + 1);
      form.setFieldsValue({
        registrationNum: registrationNumber,
      });
    }
  }, []);

  const onRegister = (values) => {
    localStorage.setItem("lastUpdatedRegNum", registrationNumber);
    let registrationObj = {
      registrationNum: registrationNumber,
      branchName: values.branchName,
      stateName: values.stateName,
      aadharNum: values.aadharNum,
      farmerName: values.farmerName,
      farmerBankName: values.farmerBankName,
      farmerBankBranch: values.farmerBankBranch,
      farmerBankIfsc: values.farmerBankIfsc,
      farmerBankAccNum: values.farmerBankAccNum,
      passBook: values.passBook,
      landArea: values.landArea,
      fatherName: values.fatherName,
      village: values.village,
      gramPanchayat: values.gramPanchayat,
      mandal: values.mandal,
      district: values.district,
      pin: values.pin,
      mobileNo: values.mobileNo,
      category: values.category,
      registrationDate: values.registrationDate,
      lastUpdateDate: values.lastUpdateDate,
    };
    db.formRegistrations.add(registrationObj);
    message.success("Successfully Registered");
    history.push("/dashboard");
    form.resetFields();
  };

  return (
    <div>
      <Row className="headerRow">
        <Col span={2}>
          <img src={MenuIcon} className="menuIcon" alt="" />
        </Col>
        <Col span={10}>
          <label className="posHeadLabel">{tillData.tillAccess.csBunit.name}</label>
        </Col>
        <Col span={4}>
          <label className="tillName">{tillData.tillAccess.cwrTill.till}</label>
        </Col>
        <Col span={4} className="right">
          <label className="time">{displayClock}</label>
        </Col>
        <Col span={4} className="right">
          <img src={POSLogo} className="posLogoIcon" alt="" onClick={handlePosLogo} />
        </Col>
      </Row>
      <div className="registrationFormDiv">
        <Form onFinish={onRegister} form={form} name="farmerRegistration">
          <Row>
            <Col span={8}>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Registration Number</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="registrationNum" className="registrationFormItem">
                    <Input className="registrationInputReadOnly" placeholder="Registration Number" readOnly />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Branch Name</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="branchName" className="registrationFormItem">
                    <Input className="registrationInput" placeholder="Branch Name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">State Name</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="stateName" className="registrationFormItem">
                    <Input className="registrationInput" placeholder="State Name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Aadhar Num*</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="aadharNum" rules={[{ required: true, message: "Please Enter Aadhar Num" }]} className="registrationFormItem">
                    <Input type="number" className="registrationInput" placeholder="Aadhar Num" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Farmer Name*</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="farmerName" rules={[{ required: true, message: "Please Enter Name" }]} className="registrationFormItem">
                    <Input className="registrationInput" placeholder="Farmer Name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Farmer Bank Name*</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="farmerBankName" rules={[{ required: true, message: "Please Enter Bank Name" }]} className="registrationFormItem">
                    <Input className="registrationInput" placeholder="Farmer Bank Name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Farmer Bank Branch*</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="farmerBankBranch" rules={[{ required: true, message: "Please Enter Bank Branch" }]} className="registrationFormItem">
                    <Input className="registrationInput" placeholder="Farmer Bank Branch" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Farmer Bank Branch IFSC*</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="farmerBankIfsc" rules={[{ required: true, message: "Please Enter Bank Branch IFSC" }]} className="registrationFormItem">
                    <Input className="registrationInput" placeholder="Farmer Bank Branch IFSC" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Farmer Bank Account Num*</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="farmerBankAccNum" rules={[{ required: true, message: "Please Enter Account Num" }]} className="registrationFormItem">
                    <Input type="number" className="registrationInput" placeholder="Farmer Bank Account Num" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Pass Book</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="passBook" className="registrationFormItem">
                    <Input className="registrationInput" placeholder="Pass Book" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8} style={{ paddingLeft: 20 }}>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Land Area (In Hectares)</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="landArea" className="registrationFormItem">
                    <Input type="number" className="registrationInput" placeholder="Land Area" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Father Name*</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="fatherName" rules={[{ required: true, message: "Please Enter Father Name" }]} className="registrationFormItem">
                    <Input className="registrationInput" placeholder="Father Name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Village*</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="village" rules={[{ required: true, message: "Please Enter Village Name" }]} className="registrationFormItem">
                    <Input className="registrationInput" placeholder="Village" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Gram Panchayat*</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="gramPanchayat" rules={[{ required: true, message: "Please Enter Gram Panchayat Name" }]} className="registrationFormItem">
                    <Input className="registrationInput" placeholder="Gram Panchayat" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Mandal*</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="mandal" rules={[{ required: true, message: "Please Enter Mandal Name" }]} className="registrationFormItem">
                    <Input className="registrationInput" placeholder="Mandal" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">District*</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="district" rules={[{ required: true, message: "Please Enter District Name" }]} className="registrationFormItem">
                    <Input className="registrationInput" placeholder="District" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">PIN</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="pin" className="registrationFormItem">
                    <Input type="number" className="registrationInput" placeholder="PIN" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Mobile No*</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="mobileNo" rules={[{ required: true, message: "Please Enter Mobile Number" }]} className="registrationFormItem">
                    <Input type="number" className="registrationInput" placeholder="Mobile No" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Category</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="category" className="registrationFormItem">
                    <Radio.Group>
                      <Radio value="general" className="category">
                        General
                      </Radio>
                      <Radio value="sc" className="category">
                        SC
                      </Radio>
                      <Radio value="st" className="category">
                        ST
                      </Radio>
                      <Radio value="obc" className="category">
                        OBC
                      </Radio>
                      <Radio value="others" className="category">
                        Others
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Registration Date</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="registrationDate" className="registrationFormItem">
                    <DatePicker />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={8} style={{ paddingLeft: 20 }}>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Last Update Date</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="lastUpdateDate" className="registrationFormItem">
                    <DatePicker />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Photo Upload*</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="photoUpload" rules={[{ required: true, message: "Please Upload Photo" }]} className="registrationFormItem">
                    <Upload>
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Passbook First Page Upload*</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="passbookUpload" rules={[{ required: true, message: "Please Upload Passbook First Page" }]} className="registrationFormItem">
                    <Upload>
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Land Record Upload*</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="landRecordUpload" rules={[{ required: true, message: "Please Upload Land Record" }]} className="registrationFormItem">
                    <Upload>
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Thumb Impression</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="thumbImpression" className="registrationFormItem">
                    <Upload>
                      <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Verify OTP</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="verifyOTP" className="registrationFormItem">
                    <Button htmlType="submit" className="verifyOTPBtn">
                      Verify OTP
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter="5">
                <Col span={8}>
                  <label className="registrationLabel">Verify Aadhar</label>
                </Col>
                <Col span={16}>
                  <Form.Item name="verifyAadhar" className="registrationFormItem">
                    <Button htmlType="submit" className="verifyOTPBtn">
                      Verify Aadhar
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <div>
            <Row>
              <Col span={24}>
                <Form.Item className="registrationFormItem">
                  <Button size="large" htmlType="submit" className="registerButton">
                    Register
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default FarmerRegistration;
