import React, { useState, useEffect } from "react";
import { Card, Row, Col, Form, Input, Button, Modal, Radio, DatePicker, Upload } from "antd";
import { useHistory } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { serverTokenUrl, serverUrl } from "../../constants/config";
import { clock } from "../../utility/clock";
import { timeStamp } from "../../utility/timestamp";
import { useAuth } from "../../lib/auth";
import Axios from "axios";
import db from "../../database";
import MenuIcon from "../../assets/images/menu.png";
// import logoIcon from "../../assets/images/cwpos-blue.png";
import POSLogo from "../../assets/images/cwPosLogo.png";
import "antd/dist/antd.css";
import "./styles.css";
import TextArea from "antd/lib/input/TextArea";

const FarmerRepresentativeRegistration = () => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const history = useHistory();

  // CLOCK BLOCK START
  const [displayClock, setDisplayClock] = useState(clock());
  useEffect(() => {
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  // CLOCK BLOCK END

  const handlePosLogo = () => {
    history.push("/dashboard");
  };

  const onRegister = async (values) => {
    
  };

  return (
    <div>
      <Row className="headerRow">
        <Col span={2}>
          <img src={MenuIcon} className="menuIcon" alt="" />
        </Col>
        <Col span={10}>
          <label className="posHeadLabel">{tillData.tillAccess.csBunit.name}</label>
        </Col>
        <Col span={4}>
          <label className="tillName">{tillData.tillAccess.cwrTill.till}</label>
        </Col>
        <Col span={4} className="right">
          <label className="time">{displayClock}</label>
        </Col>
        <Col span={4} className="right">
          <img src={POSLogo} className="posLogoIcon" alt="" onClick={handlePosLogo}/>
        </Col>
      </Row>
      <div className="registrationFormDiv">
          <Form onFinish={onRegister}>
            <Row>
              <Col span={9}>
                <Form.Item name="registrationNum" className="registrationFormItem">
                  <Row gutter="5">
                    <Col span={8}>
                      <label className="registrationLabel">Registration Number</label>
                    </Col>
                    <Col span={16}>
                      <Input className="registrationInput" placeholder="Registration Number" />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="branchName" className="registrationFormItem">
                  <Row gutter="5">
                    <Col span={8}>
                      <label className="registrationLabel">Branch Name</label>
                    </Col>
                    <Col span={16}>
                      <Input className="registrationInput" placeholder="Branch Name" />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="stateName" className="registrationFormItem">
                  <Row gutter="5">
                    <Col span={8}>
                      <label className="registrationLabel">State Name</label>
                    </Col>
                    <Col span={16}>
                      <Input className="registrationInput" placeholder="State Name" />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="aadharNum" rules={[{ required: true, message: "Please Enter Aadhar Num" }]} className="registrationFormItem">
                  <Row gutter="5">
                    <Col span={8}>
                      <label className="registrationLabel">Aadhar Num*</label>
                    </Col>
                    <Col span={16}>
                      <Input className="registrationInput" placeholder="Aadhar Num" />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="representativeName" rules={[{ required: true, message: "Please Enter Representative Name" }]} className="registrationFormItem">
                  <Row gutter="5">
                    <Col span={8}>
                      <label className="registrationLabel">Representative Name*</label>
                    </Col>
                    <Col span={16}>
                      <Input className="registrationInput" placeholder="Representative Name" />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="panNum" className="registrationFormItem">
                  <Row gutter="5">
                    <Col span={8}>
                      <label className="registrationLabel">PAN Number</label>
                    </Col>
                    <Col span={16}>
                      <Input className="registrationInput" placeholder="PAN Number" />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="fatherName" className="registrationFormItem">
                  <Row gutter="5">
                    <Col span={8}>
                      <label className="registrationLabel">Father Name</label>
                    </Col>
                    <Col span={16}>
                      <Input className="registrationInput" placeholder="Father Name" />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="village" className="registrationFormItem">
                  <Row gutter="5">
                    <Col span={8}>
                      <label className="registrationLabel">Village</label>
                    </Col>
                    <Col span={16}>
                      <Input className="registrationInput" placeholder="Village" />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="gramPanchayat" className="registrationFormItem">
                  <Row gutter="5">
                    <Col span={8}>
                      <label className="registrationLabel">Gram Panchayat</label>
                    </Col>
                    <Col span={16}>
                      <Input className="registrationInput" placeholder="Gram Panchayat" />
                    </Col>
                  </Row>
                </Form.Item>
								<Form.Item name="mandal" className="registrationFormItem">
                  <Row gutter="5">
                    <Col span={8}>
                      <label className="registrationLabel">Mandal</label>
                    </Col>
                    <Col span={16}>
                      <Input className="registrationInput" placeholder="Mandal" />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="district" className="registrationFormItem">
                  <Row gutter="5">
                    <Col span={8}>
                      <label className="registrationLabel">District</label>
                    </Col>
                    <Col span={16}>
                      <Input className="registrationInput" placeholder="District" />
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Col span={9} style={{ paddingLeft: 20 }}>
                <Form.Item name="mobileNo" rules={[{ required: true, message: "Please Enter Mobile Number" }]} className="registrationFormItem">
                  <Row gutter="5">
                    <Col span={8}>
                      <label className="registrationLabel">Mobile No*</label>
                    </Col>
                    <Col span={16}>
                      <Input className="registrationInput" placeholder="Mobile No" />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="farmerRepresntativeAadhar" className="registrationFormItem">
                  <Row gutter="5">
                    <Col span={8}>
                      <label className="registrationLabel">Farmer Representative Aadhar No.</label>
                    </Col>
                    <Col span={16}>
                      <Input className="registrationInput" placeholder="Farmer Representative Aadhar No" />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="registrationDate" className="registrationFormItem">
                  <Row gutter="5">
                    <Col span={8}>
                      <label className="registrationLabel">Registration Date</label>
                    </Col>
                    <Col span={16}>
                      <DatePicker />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="lastUpdateDate" className="registrationFormItem">
                  <Row gutter="5">
                    <Col span={8}>
                      <label className="registrationLabel">Last Update Date</label>
                    </Col>
                    <Col span={16}>
                      <DatePicker />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="photoUpload" rules={[{ required: true, message: "Please Upload Photo" }]} className="registrationFormItem">
                  <Row gutter="5">
                    <Col span={8}>
                      <label className="registrationLabel">Photo Upload*</label>
                    </Col>
                    <Col span={16}>
                      <Upload>
                        <Button icon={<UploadOutlined />}>Upload</Button>
                      </Upload>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="thumbImpression" className="registrationFormItem">
                  <Row gutter="5">
                    <Col span={8}>
                      <label className="registrationLabel">Thumb Impression</label>
                    </Col>
                    <Col span={16}>
                      <TextArea />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="verifyOTP" className="registrationFormItem">
                  <Row gutter="5">
                    <Col span={8}>
                      <label className="registrationLabel">Verify OTP</label>
                    </Col>
                    <Col span={16}>
                      <Button htmlType="submit" className="verifyOTPBtn">
                      Verify OTP
                    </Button>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name="verifyAadhar" className="registrationFormItem">
                <Row gutter="5">
                    <Col span={8}>
                      <label className="registrationLabel">Verify Aadhar</label>
                    </Col>
                    <Col span={16}>
                      <Button htmlType="submit" className="verifyOTPBtn">
                      Verify Aadhar
                    </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Col span={6} style={{ paddingLeft: 20 }} />
            </Row>
            <div>
              <Form.Item className="registrationFormItem">
                <Row>
                  <Col span={24}>
                    <Button size="large" htmlType="submit" className="registerButton">
                      Register
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </div>
          </Form>
      </div>
    </div>
  );
};

export default FarmerRepresentativeRegistration;
